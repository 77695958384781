import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getProjectById } from "../../APIs/api";
import Container from "../Components/Container";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import ProjectInformation from "../Components/ProjectInformation";
import ProjectInformationSkeleton from "../Components/SkeletonLoading/ProjectInformationSkeleton";
import ScrollTopButton from "../Components/ScrollTopButton";

function ProjectDetails() {
  const { projectId } = useParams();
  const [project, setProject] = useState();
  const [loading, setLoading] = useState(true);
  const lng = localStorage.getItem("language") || "English";
  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        setLoading(true);
        const projectData = await getProjectById(projectId);
        console.log(projectData);
        setProject(projectData);

        setLoading(false);
      } catch (error) {
        console.error(`Error fetching project number(${projectId}):`, error);
      }
    };
    fetchProjectData();
    window.scrollTo(0, 0);
  }, [lng]);
  return (
    <div className="h-full">
      <Container>
        <NavBar />
        {loading ? (
          <ProjectInformationSkeleton />
        ) : (
          <>
            <ProjectInformation
              project={project}
              description={
                lng === "English"
                  ? project?.description
                  : lng === "Deutsch"
                  ? project?.description_gr
                  : project?.description_ar
              }
            />
          </>
        )}
        <Footer />
      </Container>
      <ScrollTopButton />
    </div>
  );
}

export default ProjectDetails;
