import React, { useContext } from "react";
import ChooseContactUsWayItem from "./ChooseContactUsWayItem";
import { IoMdCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useTheme } from "../../Context/ThemeContext";
import { PopupContext } from "../../Context/PopupContext";
import Popup from "./Popup";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from "react-i18next";

function ChooseContactUsWay() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { ChooseContactUsWayOpen, setChooseContactUsWayOpen } =
    useContext(PopupContext);
  return (
    <Popup
      toggleSwitch={ChooseContactUsWayOpen}
      children={
        <div className="flex flex-col p-7 gap-4 max-md:items-center">
          <AiOutlineClose
            className="fixed cursor-pointer left-[10px] top-[10px] md:hidden"
            onClick={() => setChooseContactUsWayOpen(false)}
            size={20}
          />
          <h1
            className={`bg-gradient-to-r from-SecondTextColor ${
              theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
            } text-transparent bg-clip-text text-[24px] max-xl:text-[22px] max-md:text-[20px] font-bold `}
          >
            {t("Select how you would like to contact us.")}
          </h1>
          <ChooseContactUsWayItem
            link="tel:+4915733965543"
            icon={<IoMdCall />}
            text={t("Call")}
          />
          <ChooseContactUsWayItem
            link="https://wa.me/4915733965543"
            icon={<FaWhatsapp />}
            text={t("Whatsapp")}
          />
        </div>
      }
      zIndex="z-40 animate-flashing"
      className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 animate-flashing`}
      onClick={() => setChooseContactUsWayOpen(false)}
    />
  );
}

export default ChooseContactUsWay;

