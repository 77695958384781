import React, { createContext, useState } from "react";

export const PopupContext = createContext();

function PopupProvider({ children }) {
  const [privacySettingsOpen, setPrivacySettingsOpen] = useState(true);
  const [languageOpen, setLanguageOpen] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [ChooseContactUsWayOpen, setChooseContactUsWayOpen] = useState(false);
  const [studentDetailsOpen, setStudentDetailsOpen] = useState(false);
  const [makeDecisionOpen, setMakeDecisionOpen] = useState(false);
  const [type, setType] = useState("");
  const [action, setAction] = useState("");
  const [myLang, setMyLang] = useState("English");
  const [dir, setDir] = useState("ltr");

  const contextValue = {
    privacySettingsOpen,
    setPrivacySettingsOpen,
    languageOpen,
    setLanguageOpen,
    sideBarOpen,
    setSideBarOpen,
    ChooseContactUsWayOpen,
    setChooseContactUsWayOpen,
    studentDetailsOpen,
    setStudentDetailsOpen,
    makeDecisionOpen,
    setMakeDecisionOpen,
    type,
    setType,
    action,
    setAction,
    myLang,
    setMyLang,
    dir,
    setDir,
  };
  return (
    <PopupContext.Provider value={contextValue}>
      {children}
    </PopupContext.Provider>
  );
}

export default PopupProvider;
