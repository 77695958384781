import Cookies from "js-cookie";
import React, { useState } from "react";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  ErrorToast,
  SuccessToast,
} from "../../Website/Components/ToastifyNotification/Toast";
import { adminLogin } from "../../APIs/api";
function LoginAdmin() {
  const lng = localStorage.getItem("language") || "English";
  const { t } = useTranslation();
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", adminEmail);
    formData.append("password", password);
    try {
      setLoading(true);
      const response = await adminLogin(formData);
      console.log(response);
      if (response.status === "success") {
        Cookies.set("tecrekAdminToken", response.data.token);
        SuccessToast(response.message);
        window.location.pathname = "/dashboard";
      }
    } catch (error) {
      console.error(error);
      ErrorToast(error);
    }
    setLoading(false);
  };

  return (
    <div
      className={`max-md:bg-white md:bg-[#fbf6f6] h-screen flex justify-center md:items-center relative`}
    >
      <div
        className={`bg-white text-MainTextColor max-md:w-full md:border md:border-MainBgColorDM/45 md:shadow-xl rounded-[10px] p-4 md:py-[20px] md:px-[36px] md:w-[402px] md:m-auto overflow-hidden`}
      >
        <h1 className="text-center my-[15px] font-[700] text-[25px] max-md:text-[18px] leading-[32px]">
          {t("Admin Login")}
        </h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <label className="font-[700] max-md:text-[14px]" htmlFor="email">
            {t("Email")} <span className="text-red-600">*</span> :
          </label>
          <input
            className={`bg-[#fbf6f6] border-[#b5a7ac66] hover:border-MainBgColorDM/50 focus:border-MainBgColorDM/50 w-full mb-3 md:h-[46px] rounded-[8px] text-[16px] font-[500] border-2 outline-none px-3 py-1 max-md:text-[14px] md:py-[21px] md:px-[19px] focus:border-2 focus:ring-0 duration-300 cursor-pointer`}
            type="text"
            placeholder={t("Your Email")}
            value={adminEmail}
            id="email"
            onChange={(e) => setAdminEmail(e.target.value)}
          />
          <label className="font-[700] max-md:text-[14px]" htmlFor="password">
            {t("Password")} <span className="text-red-600">*</span> :
          </label>
          <div className="relative text-text16 max-md:text-text14">
            <input
              className={`${
                lng === "Arabic"
                  ? "pr-3 pl-6 md:pl-[35px] md:pr-[19px]"
                  : "pl-3 pr-6 md:pl-[19px] md:pr-[35px]"
              } bg-[#fbf6f6] border-[#b5a7ac66] hover:border-MainBgColorDM/50 focus:border-MainBgColorDM/50 w-full md:h-[46px] rounded-[8px] text-[16px] font-[500] border-2 outline-none py-1 max-md:text-[14px] md:py-[21px] focus:border-2 focus:ring-0 duration-300 cursor-pointer`}
              type={showPassword ? "text" : "password"}
              placeholder={t("Your Password")}
              value={password}
              id="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <IoEye
              onClick={() => setShowPassword(true)}
              className={`${showPassword ? "hidden" : ""} ${
                lng === "Arabic"
                  ? "left-4 max-md:left-2"
                  : "right-4 max-md:right-2"
              } absolute cursor-pointer top-[50%] -translate-y-[50%]`}
            />
            <IoEyeOff
              onClick={() => setShowPassword(false)}
              className={`${showPassword ? "" : "hidden"} ${
                lng === "Arabic"
                  ? "left-4 max-md:left-2"
                  : "right-4 max-md:right-2"
              } absolute cursor-pointer top-[50%] -translate-y-[50%]`}
            />
          </div>
          <button
            className={`${
              loading ? "opacity-30 cursor-default pointer-events-none" : ""
            } flex justify-center items-center md:min-h-[46px] min-h-[29px] w-full md:min-w-[209px] text-[20px] max-md:px-3 max-md:py-1 max-md:text-[14px] text-[#fff] font-[700] outline-none rounded-[8px] bg-MainBgColorDM/75 hover:bg-MainBgColorDM/85 my-[10px] duration-300`}
            type="submit"
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              t("Login")
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default LoginAdmin;
