import React, { useContext, useEffect, useState } from "react";
import Container from "../../Website/Components/Container";
import LanguageButton from "../../Website/Components/LanguageButton";
import SelectLanguagePopup from "../../Website/Components/SelectLanguagePopup";
import logoLM from "../../assets/Logo/logoLM.png";
import { useTranslation } from "react-i18next";
import { getCourseStudents } from "../../APIs/api";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import Popup from "../../Website/Components/Popup";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Pagination from "../../Website/Components/Pagination";
import { FaPowerOff } from "react-icons/fa";
import MakeDecision from "../../Website/Components/MakeDecision";

function Dashboard() {
  const { t } = useTranslation();
  const lng = localStorage.getItem("language") || "English";
  const {
    studentDetailsOpen,
    setStudentDetailsOpen,
    setMakeDecisionOpen,
    setAction,
  } = useContext(PopupContext);
  const [loading, setLoading] = useState(true);
  const [students, setStudents] = useState([]);
  const [studentDetails, setStudentDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    console.log(selectedPage);
    setCurrentPage(selectedPage);
  };

  const handleStudentDetails = (student) => {
    setStudentDetails(student);
    setStudentDetailsOpen(true);
  };

  const fetchStudentsData = async () => {
    try {
      setLoading(true);
      const response = await getCourseStudents();
      console.log(response);
      setStudents(response.data.data);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
    } catch (err) {
      console.error("fetch students data err : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchStudentsData();
  }, []);
  return (
    <div className="bg-white">
      <SkeletonTheme baseColor={`#F5F5F5`} highlightColor={`#E0E0E0`}>
        <div className="px-2 max-md:px-[2%] mx-auto py-1 flex justify-between items-center border-b border-b-MainBgColorDM/15 shadow-lg">
          <div className="w-[100px] h-[40px]">
            <img
              className="w-full h-full object-contain"
              src={logoLM}
              alt="My Logo"
            />
          </div>
          <div className="flex items-center gap-2">
            <LanguageButton className={`max-w-[75px] my-0`} navMode={true} />
            <div
              onClick={() => {
                setAction("logout");
                setMakeDecisionOpen(true);
              }}
              className="text-MainTextColor font-[700] cursor-pointer"
            >
              <div className="max-md:hidden">{t("Logout")}</div>
              <FaPowerOff className="md:hidden" />
            </div>
          </div>
          <SelectLanguagePopup dashMode={true} />
          <MakeDecision />
        </div>
        <Container>
          <div className="flex flex-col text-MainTextColor gap-5 my-5">
            <div className="font-[700] max-md:text-[14px]">
              {t("Course students")}
            </div>
            <table
              className={`border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM rounded-xl border max-md:text-[12px]`}
            >
              <thead>
                <tr>
                  <th
                    className={`border-gray-800 border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Id")}
                  </th>
                  <th
                    className={`border-gray-800 border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Name")}
                  </th>
                  <th
                    className={`border-gray-800 border px-3 py-2 max-md:px-2 max-md:py-1 w-[40px]`}
                  ></th>
                </tr>
              </thead>
              <tbody>
                {loading
                  ? Array.from({ length: 10 }).map((_, outIndex) => (
                      <tr key={outIndex}>
                        {Array.from({ length: 3 }).map((_, inIndex) => (
                          <td key={inIndex}>
                            <Skeleton height={25} />
                          </td>
                        ))}
                      </tr>
                    ))
                  : students.map((student) => (
                      <tr key={student.id}>
                        <td
                          onClick={() => handleStudentDetails(student)}
                          className={`border-gray-800 border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                        >
                          <span className="mx-auto cursor-pointer">
                            {student.number}
                          </span>
                        </td>
                        <td
                          onClick={() => handleStudentDetails(student)}
                          className={`border-gray-800 border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                        >
                          <span className="mx-auto cursor-pointer">
                            {student.first_name} {student.last_name}
                          </span>
                        </td>
                        <td
                          className={`border-gray-800 border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-[40px]`}
                        >
                          <span
                            onClick={() => handleStudentDetails(student)}
                            className="mx-auto cursor-pointer"
                          >
                            ...
                          </span>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              itemPerPage={itemPerPage}
              handlePageClick={handlePageClick}
            />
          </div>
        </Container>
      </SkeletonTheme>
      <Popup
        toggleSwitch={studentDetailsOpen}
        children={
          <div
            className={`md:min-w-[600px] max-md:w-full text-MainTextColor max-md:text-[12px]`}
          >
            <div className="flex items-center justify-center h-[50px] relative py-[2px] w-full border-b border-b-MainTextColor/40">
              <AiOutlineClose
                className={`${
                  lng === "Arabic" ? "left-[10px]" : "right-[10px]"
                } absolute cursor-pointer`}
                onClick={() => setStudentDetailsOpen(false)}
              />
              <h1 className={`max-md:text-[14px] font-[700]`}>
                {t("Student details")}
              </h1>
            </div>
            <div className="flex flex-col gap-2 p-2">
              <div className="">
                <div className="inline font-[700]">{t("Student id")} : </div>
                <span className="inline">{studentDetails.number}</span>
              </div>
              <div>
                <div className="inline font-[700]">{t("Student name")} : </div>
                <span className="inline">
                  {studentDetails.first_name} {studentDetails.last_name}
                </span>
              </div>
              <div>
                <div className="inline font-[700]">{t("Father name")} : </div>
                <span className="inline">{studentDetails.father_name}</span>
              </div>
              <div>
                <div className="inline font-[700]">{t("Mother name")} : </div>
                <span className="inline">{studentDetails.mother_name}</span>
              </div>
              <div>
                <div className="inline font-[700]">{t("Child age")} : </div>
                <span className="inline">{studentDetails.age}</span>
              </div>
              <div>
                <div className="inline font-[700]">
                  {t("Child's guardian email")} :{" "}
                </div>
                <span className="inline">{studentDetails.email}</span>
              </div>
              <div>
                <div className="inline font-[700]">
                  {t("Child's guardian number")} :{" "}
                </div>
                <span className="inline">{studentDetails.phone}</span>
              </div>
              <div>
                <div className="inline font-[700]">{t("Test score")} : </div>
                <span className="inline">
                  {studentDetails.final_test_score === "0.00"
                    ? t("Not tested yet")
                    : studentDetails.final_test_score}
                </span>
              </div>
              {studentDetails?.languages?.map((language) => (
                <div key={language.id}>
                  <div className="inline font-[700]">
                    {lng === "Arabic" && "المستوى في اللغة"}
                    {lng === "Arabic"
                      ? ` ${language.name_ar}`
                      : lng === "English"
                      ? language.name_en
                      : language.name_gr}{" "}
                    {lng !== "Arabic" && t("level")} :{" "}
                  </div>
                  <span
                    className={`${
                      (language.pivot.level === "بلا مستوى" ||
                        language.pivot.level === "Levelless" ||
                        language.pivot.level === "Niveaulos") &&
                      "bg-[#838383]"
                    } ${
                      (language.pivot.level === "ضعيف" ||
                        language.pivot.level === "Weak" ||
                        language.pivot.level === "Schwach") &&
                      "bg-[#c62b2b]"
                    } ${
                      (language.pivot.level === "متوسط" ||
                        language.pivot.level === "Middle" ||
                        language.pivot.level === "Mitte") &&
                      "bg-[#cb6c1f]"
                    } ${
                      (language.pivot.level === "جيد" ||
                        language.pivot.level === "Good" ||
                        language.pivot.level === "Gut") &&
                      "bg-[#517a29]"
                    } ${
                      (language.pivot.level === "ممتاز" ||
                        language.pivot.level === "Perfect" ||
                        language.pivot.level === "Perfekt") &&
                      "bg-[#23a423]"
                    } inline px-2 py-1 rounded-xl text-MainTextColorDM`}
                  >
                    {t(language.pivot.level)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        }
        noDarkMode={true}
        zIndex="z-20 animate-flashing"
        className={`${
          studentDetailsOpen ? "opacity-100 z-50" : "opacity-0"
        } border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM z-30 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText languageBoxShadow duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 animate-flashing`}
        onClick={() => setStudentDetailsOpen(false)}
      />
    </div>
  );
}

export default Dashboard;
