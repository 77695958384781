import "react-loading-skeleton/dist/skeleton.css";
import React from "react";
import Skeleton from "react-loading-skeleton";

function ProjectInformationSkeleton() {
  return (
    <div className="w-full flex flex-col mt-[30px] mb-[20px] md:my-[50px] xl:my-[110px] max-md:mt-5 gap-[30px]">
      <div className="flex flex-col gap-3">
        <Skeleton width={250} height={60} className="rounded-xl" />
        <Skeleton width="100%" height={80} className="rounded-xl" />
        <Skeleton
          width="100%"
          className="h-[300px] md:h-[500px] xl:h-[700px] mx-auto mb-[10px] rounded-2xl"
        />
        <div className="flex flex-col gap-[30px]">
          {Array.from({ length: 4 }).map((_, index) => (
            <div key={index} className="flex flex-col gap-2 max-md:px-[10px]">
              <Skeleton width={200} height={30} className="rounded-xl" />
              <Skeleton width="100%" height={50} className="rounded-xl" />
            </div>
          ))}
        </div>
      </div>
      <Skeleton width={200} height={30} className="rounded-xl" />
      <Skeleton
        width="100%"
        className="h-[300px] md:h-[500px] xl:h-[700px] rounded-2xl"
      />
      <Skeleton width={200} height={30} className="rounded-xl" />
      <Skeleton
        width="100%"
        className="h-[300px] md:h-[500px] xl:h-[700px] rounded-2xl"
      />
    </div>
  );
}

export default ProjectInformationSkeleton;
