import axios from "axios";
import { ErrorToast } from "../Website/Components/ToastifyNotification/Toast";
import Cookies from "js-cookie";

// export const BASE_URL = "http://127.0.0.1:8000";
export const BASE_URL = "https://backend.tecrek.com/public";
export const TEMP_BASE_URL = "https://tecrek-backend.tecrek.com/public";
// export const BASE_URL =
//   "https://ec2-3-70-241-33.eu-central-1.compute.amazonaws.com";

const token = Cookies.get("tecrekAdminToken")
const AdminBearerToken = token

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${AdminBearerToken}`,
  },
});

const tempApiClient = axios.create({
  baseURL: TEMP_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${AdminBearerToken}`,
  },
});

export async function language() {
  try {
    const response = await apiClient.get("/api/language");
    return response;
  } catch (error){
    console.error("Error language : ",error)
  }
}

export async function getUserInfo() {
  try {
    const response = await apiClient.get("/api/tecrek");
    // localStorage.setItem("dataAllReadyFetched", true);
    return response.data;
  } catch {
    return;
  }
}

export async function getProjects() {
  try {
    const response = await apiClient.get("/api/Product");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching projects : ", error);
  }
}

export async function getProjectById(id) {
  try {
    const response = await apiClient.get(`/api/Productbyid?product_id=${id}`);
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching project number(${id}) :`, error);
  }
}

export async function postContactUs(Data) {
  try {
    const response = await apiClient.post("/api/ContactUs", Data);
    return response.data.data;
  } catch (error) {
    console.error("Error post contact us data : ", error.response);
    ErrorToast(error.response.data.errors[0]);
  }
}

export async function registerInCourse(Data) {
  try {
    const response = await tempApiClient.post("/api/Student", Data);
    return response.data.data;
  } catch (error) {
    console.error("Error register in course : ", error.response);
    ErrorToast(error.response.data.errors[0]);
  }
}

export async function getCourseDetails(id) {
  try {
    const response = await tempApiClient.get(`/api/Course?course_id=${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching course details : `, error);
    return;
  }
}

export async function getSurveyQuetions(student_number) {
  try {
    const response = await tempApiClient.get(`/api/Question?student_number=${student_number}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching survey quetions : `, error);
    return;
  }
}

export async function getTestQuetions(student_number) {
  try {
    const response = await tempApiClient.get(`/api/QuestionTest?student_number=${student_number}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching survey quetions : `, error);
    return;
  }
}

export async function postSurveyAnswers(student_id,data) {
  try {
    const response = await tempApiClient.post(`/api/Answer?student_id=${student_id}`,data);
    return response.data;
  } catch (error) {
    console.error(`Error post survey answers : `, error);
    return;
  }
}

export async function postTestAnswers(student_id,data) {
  try {
    const response = await tempApiClient.post(`/api/AnswerTest?student_id=${student_id}`,data);
    return response.data;
  } catch (error) {
    console.error(`Error post survey answers : `, error);
    return;
  }
}


export async function getTeamMembers() {
  try {
    const response = await apiClient.get(`/api/Employe`);
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching team members : `, error);
    return;
  }
}

export async function getReviews() {
  try {
    const response = await apiClient.get(`/api/Review`);
    return response.data.data;
  } catch (error) {
    console.error(`Error fetching reviews : `, error);
    return;
  }
}

// dashboard

export async function adminLogin(data) {
  try {
    const response = await tempApiClient.post(`/api/loginAdmin`,data);
    return response.data;
  } catch (error) {
    console.error(`Error post admin login : `, error);
    ErrorToast(error.response.data.errors[0]);
    return;
  }
}

export async function getCourseStudents() {
  try {
    const response = await tempApiClient.get(`/api/Student`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching course students : `, error);
    return;
  }
}

export async function logout() {
  try {
    const response = await tempApiClient.get(`/api/logout`);
    return response.data;
  } catch (error) {
    console.error(`Error logout : `, error);
    return;
  }
}

