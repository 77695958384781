import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function WhatWeDoItem({ image, title, description }) {
  const { theme } = useTheme();
  const lng = localStorage.getItem("language");
  return (
    <div
      dir={lng === "Arabic" ? "rtl" : "ltr"}
      className={`bg-gradient-to-br from-SecondTextColor ${
        theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
      } min-h-[350px] max-h-[350px] max-xl:min-h-[335px] max-xl:max-h-[335px] max-lg:min-h-[320px] max-lg:max-h-[320px] max-md:min-h-[300px] max-md:max-h-[300px] rounded-[20px] overflow-hidden shadow duration-300 p-[3px]`}
    >
      <div
        className={`${
          theme === "dark"
            ? "text-MainTextColorDM bg-[#0f2931]"
            : "text-MainTextColor bg-[#c4d0d2]"
        } flex flex-col gap-3 h-full max-md:min-h-[295px] max-md:max-h-[295px] p-2 rounded-[20px]`}
      >
        <div className="flex flex-col gap-3 basis-[35%]">
          <img className="w-[90px] h-[90px]" src={image} alt="" />
          <h1 className="text-[28px] max-md:text-[24px] font-semibold">
            {title}
          </h1>
        </div>
        <p className="text-[18px] max-md:text-text16 font-medium basis-[65%]">
          {description}
        </p>
      </div>
    </div>
  );
}

export default WhatWeDoItem;
