import React, { useEffect, useState } from "react";
import Container from "../../Components/Container";
import NavBar from "../../Components/NavBar";
import Landing from "./Sections/Landing";
import AboutUs from "./Sections/AboutUs";
import WhatWeDo from "./Sections/WhatWeDo";
import OurWork from "./Sections/OurWork";
import ContactUs from "./Sections/ContactUs";
import Footer from "../../Components/Footer";
import Reviews from "./Sections/Reviews";
import PrivacySettings from "../../Components/PrivacySettings";
import { getUserInfo, language } from "../../../APIs/api";
import ScrollTopButton from "../../Components/ScrollTopButton";
import LoadingPage from "../../Components/LoadingPage";

function Home() {
  const [loading, setLoading] = useState(true);
  const whatIsLanguage = async () => {
    try {
      const response = await language();
      console.log(response.data.language);
      localStorage.setItem(
        "language",
        response.data.language === "Arabic"
          ? "Arabic"
          : response.data.language === "German"
          ? "Deutsch"
          : "English"
      );
    } catch (error) {
      console.error("lang err : ", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchMainData = async () => {
      await getUserInfo();
    };
    fetchMainData();
    whatIsLanguage();
    window.scrollTo(0, 0);
  }, []);
  return loading ? (
    <LoadingPage />
  ) : (
    <div>
      <Container>
        <NavBar showLink={true} />
        <Landing />
        <AboutUs />
        <WhatWeDo />
        <OurWork />
        <Reviews />
        <ContactUs />
        <Footer showLink={true} />
      </Container>
      <PrivacySettings />
      <ScrollTopButton />
    </div>
  );
}

export default Home;
