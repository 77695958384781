import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";

function MainButton({ onClick, content, link, className, type }) {
  const { theme } = useTheme();
  if (type === "submit") {
    return (
      <button
        type={type}
        onClick={onClick}
        className={`${className} ${
          theme === "dark"
            ? "bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM text-MainTextColorDM border-t-MainTextColorDM border-gray-400 hover:border-MainTextColorDM"
            : "bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor text-MainTextColor border-t-MainTextColor border-gray-500 hover:border-MainTextColor"
        } min-w-[120px] py-2 px-4 flex justify-center items-center font-medium rounded-[60px] border border-b-[2px] duration-1000`}
      >
        {content}
      </button>
    );
  } else {
    return (
      <Link
        to={link}
        onClick={onClick}
        className={`${className} ${
          theme === "dark"
            ? "bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM text-MainTextColorDM border-t-MainTextColorDM border-gray-400 hover:border-MainTextColorDM"
            : "bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor text-MainTextColor border-t-MainTextColor border-gray-500 hover:border-MainTextColor"
        } min-w-[120px] flex justify-center items-center font-medium rounded-[60px] border border-b-[2px] duration-1000`}
      >
        <button
          type={type}
          className="min-w-[120px] h-full py-2 px-4 rounded-[60px]"
        >
          {content}
        </button>
      </Link>
    );
  }
}

export default MainButton;
