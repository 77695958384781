import React, { useEffect, useState } from "react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { useTheme } from "../../../../Context/ThemeContext";
import { Link } from "react-router-dom";
import left_circles from "../../../../assets/OurWork/left_circles.png";
import Product from "../../../Components/Product";
import { getProjects } from "../../../../APIs/api";
import { useTranslation } from "react-i18next";
import ProductSkeleton from "../../../Components/SkeletonLoading/ProductSkeleton";

function OurWork() {
  const { theme } = useTheme();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const lng = localStorage.getItem("language") || "English";
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const projectsData = await getProjects();
        setProjects(projectsData);
        setLoading(false);
      } catch (error) {
        console.log("fetch projects error", error);
      }
    };
    fetchProjectsData();
  }, []);

  return (
    <div
      id="our_work"
      className={`${
        theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
      } pt-[60px] max-md:p-[10px]`}
    >
      <div>
        <div className="flex flex-col font-semibold">
          <h1
            className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${
              theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
            } text-transparent bg-clip-text`}
          >
            {t("OUR WORK")}
          </h1>
          <h2 className="leading-[52px] text-[48px] max-xl:text-[40px] max-md:text-[22px] max-md:leading-[32px]">
            {t("We collaborate closely with our clients to design solutions")}
          </h2>
          {loading ? (
            <ProductSkeleton />
          ) : (
            <div className="flex flex-col gap-10 max-md:gap-2 max-md:mb-5 mt-[30px]">
              {projects && projects.length > 0 && (
                <Product
                  className={"flex"}
                  image={projects[0].logo}
                  circles={left_circles}
                  circlesClassName={
                    "left-0 w-[200px] max-lg:w-[150px] max-md:hidden"
                  }
                  id={projects[0].id}
                  name={projects[0].name}
                  type={
                    lng === "English"
                      ? projects[0].type
                      : lng === "Deutsch"
                      ? projects[0].type_gr
                      : projects[0].type_ar
                  }
                  mainDescription={
                    lng === "English"
                      ? projects[0].main_description
                      : lng === "Deutsch"
                      ? projects[0].main_description_gr
                      : projects[0].main_description_ar
                  }
                />
              )}
              <Link
                to="/ourWork"
                className="flex justify-center items-center gap-1 hover:text-SecondTextColor duration-300 font-semibold mx-auto mb-2 cursor-pointer"
              >
                <h1>{t("More projects")}</h1>
                {lng === "Arabic" ? <IoIosArrowBack /> : <IoIosArrowForward />}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OurWork;
