import React from "react";
import { Link } from "react-router-dom";

function ChooseContactUsWayItem({ link, className, icon, text }) {
  return (
    <Link
      to={link}
      target="_blank"
      className={`${className} text-[24px] max-xl:text-[22px] max-md:text-[20px] flex items-center gap-2 hover:text-SecondTextColor duration-300 w-fit`}
    >
      {icon}
      <div>{text}</div>
    </Link>
  );
}

export default ChooseContactUsWayItem;
