import React, { useEffect, useState } from "react";
import TeamMember from "./TeamMember";
import { useTheme } from "../../Context/ThemeContext";
import { getTeamMembers } from "../../APIs/api";
import TeamMemberSkeleton from "./SkeletonLoading/TeamMemberSkeleton";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function Team() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchTeamData = async () => {
      setLoading(true);
      try {
        const response = await getTeamMembers();
        console.log(response);
        setTeam(response);
        setLoading(false);
      } catch (error) {
        console.error(`Error fetching team members err :`, error);
      }
    };
    fetchTeamData();
  }, []);
  return (
    <div className="flex flex-col gap-5 pt-[60px] max-md:p-[10px] max-md:mt-[20px] max-md:mb-[10px] mb-14">
      <div className="flex flex-col gap-2r">
        <h1
          className={`bg-gradient-to-r from-SecondTextColor ${
            theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
          } text-transparent bg-clip-text text-[24px] max-xl:text-[22px] max-md:text-[20px] mb-3 font-bold `}
        >
          {t("People are the key to success")}
        </h1>
        <h2 className="md:text-text18 max-md:text-text16 font-semibold mb-3">
          {t("We feel proud every time when someone new is joining")}
        </h2>
      </div>
      <div style={{ direction: "ltr" }} className={`mySwiper`}>
        <Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={10}
          pagination={{ clickable: true }}
          navigation
          breakpoints={{
            1280: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 2,
            },
          }}
        >
          {loading
            ? Array.from({ length: 4 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <TeamMemberSkeleton />
                </SwiperSlide>
              ))
            : team &&
              team?.map((teamMember) => {
                return (
                  <SwiperSlide key={teamMember.id}>
                    <TeamMember
                      image={teamMember.url_image}
                      name={teamMember.name}
                      position={teamMember.position}
                      link={teamMember.linked}
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
    </div>
  );
}

export default Team;
