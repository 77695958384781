import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
      // NavItems
      Home: "Home",
      "About Us": "About Us",
      "Our Work": "Our Work",
      "Courses": "Courses",
      "Contact Us": "Contact Us",
      // Landing Section
      "We Bring Your Digital Dreams to Life.":
        "We Bring Your Digital Dreams to Life.",
      'After TECREK revamped our app, our sales conversion rates soared." - TECREK Customer':
        'After TECREK revamped our app, our sales conversion rates soared." - TECREK Customer',
      "Talk to us": "Talk to us",
      // AboutUs section
      "ABOUT US": "ABOUT US",
      "We Will Be Your Technology Partner for Digital Transformation":
        "We Will Be Your Technology Partner for Digital Transformation",
      "TECREK specializes in web and app development services with a strategic focus on database-driven solutions, including E-Commerce and Inventory Management. Established in 2023, we have become a pivotal technology ally for over 11 firms across Malaysia, Dubai, Syria, and Germany":
        "TECREK specializes in web and app development services with a strategic focus on database-driven solutions, including E-Commerce and Inventory Management. Established in 2023, we have become a pivotal technology ally for over 11 firms across Malaysia, Dubai, Syria, and Germany",
      "Our mission is to enable our partner businesses to thrive in the dynamic and rapidly evolving technological landscape of today.":
        "Our mission is to enable our partner businesses to thrive in the dynamic and rapidly evolving technological landscape of today.",
      "Our vision is to catapult our partner firms into new heights of success, adapting swiftly and effectively to the technological shifts of the modern world.":
        "Our vision is to catapult our partner firms into new heights of success, adapting swiftly and effectively to the technological shifts of the modern world.",
      // WhatWeDo section
      "WHAT WE DO": "WHAT WE DO",
      "Web Applications": "Web Applications",
      "Tailored multi-functional web platforms, designed specifically for your business needs.":
        "Tailored multi-functional web platforms, designed specifically for your business needs.",
      "Mobile Apps": "Mobile Apps",
      "Develop mobile applications efficiently and affordably. Available for both Android and iOS platforms.":
        "Develop mobile applications efficiently and affordably. Available for both Android and iOS platforms.",
      "Digital Marketing": "Digital Marketing",
      "Accelerate your success with our dynamic digital marketing solutions, tailored to maximize your online impact.":
        "Accelerate your success with our dynamic digital marketing solutions, tailored to maximize your online impact.",
      "Managed Social Media": "Managed Social Media",
      "Elevate your brand with our strategic social media management services, designed to boost engagement and visibility.":
        "Elevate your brand with our strategic social media management services, designed to boost engagement and visibility.",
      "E-Commerce": "E-Commerce",
      "Enhance your online sales with our versatile ready-made and custom e-commerce solutions.":
        "Enhance your online sales with our versatile ready-made and custom e-commerce solutions.",
      "API Development": "API Development",
      "Create your own REST API with comprehensive documentation to support third-party integrations.":
        "Create your own REST API with comprehensive documentation to support third-party integrations.",
      Maintenance: "Maintenance",
      "Enjoy a hassle-free experience as we handle your web and app maintenance, allowing you to concentrate on your core business.":
        "Enjoy a hassle-free experience as we handle your web and app maintenance, allowing you to concentrate on your core business.",
      "Web Design": "Web Design",
      "Modernize your website with cutting-edge designs and the latest UX/UI principles to enhance user engagement.":
        "Modernize your website with cutting-edge designs and the latest UX/UI principles to enhance user engagement.",
      // OurWork section
      "OUR WORK": "OUR WORK",
      "We collaborate closely with our clients to design solutions":
        "We collaborate closely with our clients to design solutions",
      "Know more": "Know more",
      "More projects": "More projects",
      // Reviews section
      "THE REVIEWS": "THE REVIEWS",
      // ContactUs section
      "TALK TO US": "TALK TO US",
      "Join us in forging your digital future":
        "Join us in forging your digital future",
      "Whether you’re ready to begin or have inquiries, reach out to discuss how we can assist you.":
        "Whether you’re ready to begin or have inquiries, reach out to discuss how we can assist you.",
      "First Name*": "First Name*",
      "Last Name*": "Last Name*",
      "Phone Number*": "Phone Number*",
      "Email*": "Email*",
      "Company Name*": "Company Name*",
      "Message*": "Message*",
      "Child Name*": "Child Name*",
      "Father Name*": "Father Name*",
      "Family Name": "Family Name",
      "Child Age*": "Child Age*",
      "Mother Name*": "Mother Name*",
      "Parent's Email*": "Parent's Email*",
      "Parent's Phone*": "Parent's Phone*",
      "Arabic level*": "Arabic level*",
      "Choose Arabic Level (Conversation)*": "Choose Arabic Level (Conversation)*",
      "Kurdish Level*": "Kurdish Level*",
      "Choose Kurdish Level (Conversation)*": "Choose Kurdish Level (Conversation)*",
      "German Level*": "German Level*",
      "Choose German Level (Conversation)*": "Choose German Level (Conversation)*",
      "English Level*": "English Level*",
      "Choose English Level (Conversation)*": "Choose English Level (Conversation)*",
            "بلا مستوى" : "Levelless",
      "ضعيف" : "Weak",
      "متوسط" : "Middle",
      "جيد" : "Good",
      "ممتاز" : "Perfect",
      "Levelless": "Levelless",
      "Weak": "Weak",
      "Middle": "Middle",
      "Good": "Good",
      "Perfect": "Perfect",
      "Niveaulos": "Levelless",
      "Schwach": "Weak",
      "Mitte": "Middle",
      "Gut": "Good",
      "Perfekt": "Perfect",
      Send: "Send",
      "Ok": "Ok",
      "Thank you for reaching out! Your message is important to us, and we’re looking forward to helping you. One of our team members will get back to you shortly.":
        "Thank you for reaching out! Your message is important to us, and we’re looking forward to helping you. One of our team members will get back to you shortly.",
      "Thank you for contacting us. We will inform you of the registration confirmation via email or WhatsApp message if your application for the September Computer Basics course is accepted.": "Thank you for contacting us. We will inform you of the registration confirmation via email or WhatsApp message if your application for the September Computer Basics course is accepted.",
      "Next": "Next",
      "This is your child's number": "This is your child's number",
      "Remember the previous number well": "Remember the previous number well",
      // Course Details Page
      "Course Details": "Course Details",
      "Here you will find all the course details": "Here you will find all the course details",
      "Course Title": "Course Title",
      "Course description": "Course description",
      "Age": "Age",
      "Max students in class": "Max students in class",
      "Course duration": "Course duration",
      "Session duration": "Session duration",
      "Session time": "Session time",
      "Session dates": "Session dates",
      "Registration limit": "Registration limit",
      "Cost": "Cost",
      "What will we learn?": "What will we learn?",
      "So": "So",
      "Note on registration": "Note on registration",
      "Close": "Close",
      // SurveyByStudentNumber
      "We invite all parents to participate in completing 25 questions designed to deepen our understanding of your children’s personalities. Your participation is crucial in helping us tailor an educational experience that fits the unique traits and needs of each child. We guarantee complete confidentiality of all information provided and promise that it will be used solely to enhance the support we offer our students.": "We invite all parents to participate in completing 25 questions designed to deepen our understanding of your children’s personalities. Your participation is crucial in helping us tailor an educational experience that fits the unique traits and needs of each child. We guarantee complete confidentiality of all information provided and promise that it will be used solely to enhance the support we offer our students.",
      "Thank you for your engagement and commitment to improving education at our school.": "Thank you for your engagement and commitment to improving education at our school.",
      "We invite all parents to participate in answering 25 questions designed to assess your children’s understanding of computer basics. This test will help us understand your child’s technological capabilities and identify the support needed to enhance their skills. We appreciate your cooperation and assure you of the confidentiality and security of the information provided.": "We invite all parents to participate in answering 25 questions designed to assess your children’s understanding of computer basics. This test will help us understand your child’s technological capabilities and identify the support needed to enhance their skills. We appreciate your cooperation and assure you of the confidentiality and security of the information provided.",
      "Thank you for your participation and ongoing support.": "Thank you for your participation and ongoing support.",
      "Child Name": "Child Name",
      "Please specify": "Please specify",
      "This question is required": "This question is required",
      "Thank you for contacting us, we will notify you of the test results via email or WhatsApp message.": "Thank you for contacting us, we will notify you of the test results via email or WhatsApp message.",
      // Footer
      "Quick Links": "Quick Links",
      "Contact us": "Contact us",
      "Get in touch": "Get in touch",
      "Enable Light Mode": "Enable Light Mode",
      "Enable Dark Mode": "Enable Dark Mode",
      // SelectLanguagePopup
      "Choose Language": "Choose Language",
      English: "English",
      Deutsch: "Deutsch",
      Arabic: "العربية",
      // ProjectInformation
      "Web images": "Web images",
      "Mobile app images": "Mobile app images",
      // AboutUs Page
      "Empowering Innovation: Our Journey Begins":
        "Empowering Innovation: Our Journey Begins",
      "At TECREK, we are not just a technology company; we are pioneers in the digital realm. Founded in 2023, we began as a small team with big ambitions. Today, we have grown into a diverse collective of professionals from various countries, each bringing unique degrees and expertise to our mission. We specialize in crafting smart, fast, and efficient digital solutions that streamline your business operations. Our innovative web platforms, mobile applications, and administrative panels are designed to be intuitive and user-friendly, saving you time and enhancing productivity. By simplifying complex processes, we help you focus on what truly matters—growing your business and fostering creativity among your team.":
        "At TECREK, we are not just a technology company; we are pioneers in the digital realm. Founded in 2023, we began as a small team with big ambitions. Today, we have grown into a diverse collective of professionals from various countries, each bringing unique degrees and expertise to our mission. We specialize in crafting smart, fast, and efficient digital solutions that streamline your business operations. Our innovative web platforms, mobile applications, and administrative panels are designed to be intuitive and user-friendly, saving you time and enhancing productivity. By simplifying complex processes, we help you focus on what truly matters—growing your business and fostering creativity among your team.",
      "Be yourself and belong": "Be yourself and belong",
      "We foster an inclusive workplace culture, boost social participation, and advocate it outside of TECREK":
        "We foster an inclusive workplace culture, boost social participation, and advocate it outside of TECREK",
      "We feel accountable for making our workspace culture inclusive, transparent, and socially responsible so everyone can realize their full potential while being their own person. We strive to make everyone feel a part of our team.":
        "We feel accountable for making our workspace culture inclusive, transparent, and socially responsible so everyone can realize their full potential while being their own person. We strive to make everyone feel a part of our team.",
      "Build a career that suits your lifestyle, in a company where your voice matters.":
        "Build a career that suits your lifestyle, in a company where your voice matters.",
      "Shaping a Smarter Future: Our Commitment to Your Success":
        "Shaping a Smarter Future: Our Commitment to Your Success",
      "Our goal at TECREK is profound yet simple: to make your business operations as efficient as possible, allowing you and your employees more time to innovate and succeed. We understand that in the fast-paced world of business, efficiency is key to maintaining a competitive edge. We are committed to ongoing development and refinement of our services to ensure they not only meet but exceed your needs. Each project we undertake is a step towards building a better and more helpful world. Join us as we continue to innovate and redefine the possibilities within the digital landscape. Together, we can transform challenges into opportunities and pave the way for a brighter, more successful future.":
        "Our goal at TECREK is profound yet simple: to make your business operations as efficient as possible, allowing you and your employees more time to innovate and succeed. We understand that in the fast-paced world of business, efficiency is key to maintaining a competitive edge. We are committed to ongoing development and refinement of our services to ensure they not only meet but exceed your needs. Each project we undertake is a step towards building a better and more helpful world. Join us as we continue to innovate and redefine the possibilities within the digital landscape. Together, we can transform challenges into opportunities and pave the way for a brighter, more successful future.",
      // Team
      "People are the key to success": "People are the key to success",
      "We feel proud every time when someone new is joining":
        "We feel proud every time when someone new is joining",
      // PrivacySettings 
      "Data Protection Options": "Data Protection Options",
      "Our website employs third-party tracking technologies from other websites to enhance and personalize our services, as well as for advertising objectives. The installation of optional cookies is contingent upon your approval, which you can withdraw at any moment.": "Our website employs third-party tracking technologies from other websites to enhance and personalize our services, as well as for advertising objectives. The installation of optional cookies is contingent upon your approval, which you can withdraw at any moment.",
      "Agree": "Agree",
      // ChooseContactUsWay 
      "Select how you would like to contact us.": "Select how you would like to contact us.",
      "Call": "Call",
      "Whatsapp": "Whatsapp",
      // LoginAdmin
      "Admin Login": "Admin Login",
      "Email": "Email",
      "Your Email": "Your Email",
      "Password": "Password",
      "Your Password": "Your Password",
      "Login": "Login",
      // Dashboard
      "Course students": "Course students",
      "Id": "Id",
      "Name": "Name",
      "Student details": "Student details",
      "Student id": "Student id",
      "Student name": "Student name",
      "Father name": "Father name",
      "Mother name": "Mother name",
      "Child age": "Child age",
      "Child's guardian email": "Email",
      "Child's guardian number": "Phone number",
      "Test score": "Test score",
      "Not tested yet": "Not tested yet",
      "level":"level",
      // MakeDecision 
      "Are you sure you want to":"Are you sure you want to",
      "Logout" : "Logout",
      "logout":"logout",
      "this":"this",
    },
  },
  Deutsch: {
    translation: {
      // NavItems
      Home: "Startseite",
      "About Us": "Über uns",
      "Our Work": "Unsere Arbeit",
      "Courses": "Kurse",
      "Contact Us": "Kontaktiere uns",
      // Landing Section
      "We Bring Your Digital Dreams to Life.":
        "Wir machen Ihre digitalen Träume wahr.",
      'After TECREK revamped our app, our sales conversion rates soared." - TECREK Customer':
        'Nachdem TECREK unsere App überarbeitet hatte, stiegen unsere Verkaufsumwandlungsraten enorm." - TECREK Kunde',
      "Talk to us": "Sprich mit uns",
      // AboutUs section
      "ABOUT US": "ÜBER UNS",
      "We Will Be Your Technology Partner for Digital Transformation":
        "Wir sind Ihr Technologiepartner für die digitale Transformationn",
      "TECREK specializes in web and app development services with a strategic focus on database-driven solutions, including E-Commerce and Inventory Management. Established in 2023, we have become a pivotal technology ally for over 11 firms across Malaysia, Dubai, Syria, and Germany":
        "TECREK ist spezialisiert auf Web- und App-Entwicklungsdienstleistungen mit einem strategischen Fokus auf datenbankgestützte Lösungen, einschließlich E-Commerce und Inventarverwaltung. Gegründet im Jahr 2023, sind wir zu einem entscheidenden technologischen Verbündeten für über 11 Firmen in Malaysia, Dubai, Syrien und Deutschland geworden.",
      "Our mission is to enable our partner businesses to thrive in the dynamic and rapidly evolving technological landscape of today.":
        "Unsere Mission ist es, unseren Partnerunternehmen zu ermöglichen, in der dynamischen und schnelllebigen technologischen Landschaft von heute zu gedeihen.",
      "Our vision is to catapult our partner firms into new heights of success, adapting swiftly and effectively to the technological shifts of the modern world.":
        "Unsere Vision ist es, unsere Partnerfirmen in neue Höhen des Erfolgs zu katapultieren, indem wir uns schnell und effektiv an die technologischen Veränderungen der modernen Welt anpassen.",
      // WhatWeDo section
      "WHAT WE DO": "WAS WIR TUN",
      "Web Applications": "Webanwendungen",
      "Tailored multi-functional web platforms, designed specifically for your business needs.":
        "Maßgeschneiderte, multifunktionale Webplattformen, die speziell auf Ihre geschäftlichen Anforderungen zugeschnitten sind.",
      "Mobile Apps": "Mobile Apps",
      "Develop mobile applications efficiently and affordably. Available for both Android and iOS platforms.":
        "Entwicklung von mobilen Anwendungen effizient und kostengünstig. Verfügbar für Android- und iOS-Plattformen.",
      "Digital Marketing": "Digital Marketing",
      "Accelerate your success with our dynamic digital marketing solutions, tailored to maximize your online impact.":
        "Beschleunigen Sie Ihren Erfolg mit unseren dynamischen digitalen Marketinglösungen, die darauf abzielen, Ihren Online-Einfluss zu maximieren.",
      "Managed Social Media": "Managed Social Media",
      "Elevate your brand with our strategic social media management services, designed to boost engagement and visibility.":
        "Steigern Sie Ihre Marke mit unseren strategischen Social-Media-Management-Diensten, die darauf ausgelegt sind, Engagement und Sichtbarkeit zu erhöhen.",
      "E-Commerce": "E-Commerce",
      "Enhance your online sales with our versatile ready-made and custom e-commerce solutions.":
        "Steigern Sie Ihren Online-Verkauf mit unseren vielseitigen fertigen und maßgeschneiderten E-Commerce-Lösungen.",
      "API Development": "API-Entwicklung",
      "Create your own REST API with comprehensive documentation to support third-party integrations.":
        "Erstellen Sie Ihre eigene REST-API mit umfassender Dokumentation zur Unterstützung von Integrationen von Drittanbietern.",
      Maintenance: "Wartung",
      "Enjoy a hassle-free experience as we handle your web and app maintenance, allowing you to concentrate on your core business.":
        "Genießen Sie ein problemloses Erlebnis, während wir uns um die Wartung Ihrer Website und App kümmern und Sie sich auf Ihr Kerngeschäft konzentrieren können.",
      "Web Design": "Webdesign",
      "Modernize your website with cutting-edge designs and the latest UX/UI principles to enhance user engagement.":
        "Modernisieren Sie Ihre Website mit innovativen Designs und den neuesten UX/UI-Prinzipien, um die Benutzerinteraktion zu verbessern.",
      // OurWork section
      "OUR WORK": "UNSERE ARBEIT",
      "We collaborate closely with our clients to design solutions":
        "Wir arbeiten eng mit unseren Kunden zusammen, um Lösungen zu entwerfen",
      "Know more": "Mehr wissen",
      "More projects": "Weitere Projekte",
      // Reviews section
      "THE REVIEWS": "DIE BEWERTUNGEN",
      // ContactUs section
      "TALK TO US": "SPRECHEN SIE MIT UNS",
      "Join us in forging your digital future":
        "Schließen Sie sich uns an und gestalten Sie Ihre digitale Zukunft",
      "Whether you’re ready to begin or have inquiries, reach out to discuss how we can assist you.":
        "Ob Sie bereit sind zu beginnen oder Fragen haben, kontaktieren Sie uns, um zu besprechen, wie wir Ihnen helfen können.",
      "First Name*": "Vorname*",
      "Last Name*": "Nachname*",
      "Phone Number*": "Telefonnummer*",
      "Email*": "E-Mail*",
      "Company Name*": "Unternehmensname*",
      "Message*": "Nachricht*",
      "Child Name*": "Kindername*",
      "Father Name*": "Vatername*",
      "Family Name": "Nachname",
      "Child Age*": "Alter des Kindes*",
      "Mother Name*": "Name der Mutter*",
      "Parent's Email*": "E-Mail des Erziehungsberechtigten*",
      "Parent's Phone*": "Telefon des Erziehungsberechtigten*",
      "Arabic level*": "Arabischniveau*",
      "Choose Arabic Level (Conversation)*": "Wählen Sie Arabischniveau (Konversation)*",
      "Kurdish Level*": "Kurdisch-Niveau*",
      "Choose Kurdish Level (Conversation)*": "Wählen Sie Kurdisch-Level (Konversation)*",
      "German Level*": "Deutschniveau*",
      "Choose German Level (Conversation)*": "Wählen Sie Deutschniveau (Konversation)*",
      "English Level*": "Englischniveau*",
      "Choose English Level (Conversation)*": "Wählen Sie Englischniveau (Konversation)*",
      "بلا مستوى" : "Niveaulos",
      "ضعيف" : "Schwach",
      "متوسط" : "Mitte",
      "جيد" : "Gut",
      "ممتاز" : "Perfekt",
      "Levelless": "Niveaulos",
      "Weak": "Schwach",
      "Middle": "Mitte",
      "Good": "Gut",
      "Perfect": "Perfekt",
      "Niveaulos": "Niveaulos",
      "Schwach": "Schwach",
      "Mitte": "Mitte",
      "Gut": "Gut",
      "Perfekt": "Perfekt",
      Send: "Senden",
      "Ok": "Ok",
      "Thank you for reaching out! Your message is important to us, and we’re looking forward to helping you. One of our team members will get back to you shortly.":
        "Vielen Dank für Ihre Kontaktaufnahme! Ihre Nachricht ist für uns wichtig, und wir freuen uns darauf, Ihnen zu helfen. Einer unserer Teammitglieder wird sich in Kürze bei Ihnen melden.",
      "Thank you for contacting us. We will inform you of the registration confirmation via email or WhatsApp message if your application for the September Computer Basics course is accepted.": "Vielen Dank, dass Sie uns kontaktiert haben. Wir werden Sie per E-Mail oder WhatsApp-Nachricht über die Registrierungsbestätigung informieren, falls Ihre Bewerbung für den Computergrundkurs im September akzeptiert wird.",
      "Next": "Nächste",
      "This is your child's number": "Dies ist die Nummer Ihres Kindes",
      "Remember the previous number well": "Merken Sie sich die vorherige Nummer gut",
      // Course Details Page
      "Course Details": "Kursdetails",
      "Here you will find all the course details": "Hier finden Sie alle Kursdetails",
      "Course Title": "Kurstitel",
      "Course description": "Kursbeschreibung",
      "Age": "Alter",
      "Max students in class": "Maximale Anzahl an Schülern im Unterricht",
      "Course duration": "Kursdauer",
      "Session duration": "Sitzungsdauer",
      "Session time": "Sitzungszeit",
      "Session dates": "Sitzungstermine",
      "Registration limit": "Registrierungslimit",
      "Cost": "Kosten",
      "What will we learn?": "Was werden wir lernen?",
      "So": "Also",
      "Note on registration": "Hinweis zur Anmeldung",
      "Close": "Zurück",
      // SurveyByStudentNumber
      "We invite all parents to participate in completing 25 questions designed to deepen our understanding of your children’s personalities. Your participation is crucial in helping us tailor an educational experience that fits the unique traits and needs of each child. We guarantee complete confidentiality of all information provided and promise that it will be used solely to enhance the support we offer our students.": "Wir laden alle Eltern ein, an der Beantwortung von 25 Fragen teilzunehmen, die darauf abzielen, unser Verständnis für die Persönlichkeiten Ihrer Kinder zu vertiefen. Ihre Teilnahme ist entscheidend dafür, dass wir eine Bildungserfahrung schaffen können, die auf die einzigartigen Eigenschaften und Bedürfnisse jedes Kindes zugeschnitten ist. Wir garantieren die vollständige Vertraulichkeit aller bereitgestellten Informationen und versprechen, dass sie ausschließlich dazu verwendet werden, die Unterstützung zu verbessern, die wir unseren Schülern bieten.",
      "Thank you for your engagement and commitment to improving education at our school.": "Vielen Dank für Ihr Engagement und Ihre Bereitschaft, die Bildung in unserer Schule zu verbessern.",
      "We invite all parents to participate in answering 25 questions designed to assess your children’s understanding of computer basics. This test will help us understand your child’s technological capabilities and identify the support needed to enhance their skills. We appreciate your cooperation and assure you of the confidentiality and security of the information provided.": "Wir laden alle Eltern ein, an der Beantwortung von 25 Fragen teilzunehmen, die darauf ausgelegt sind, das Verständnis Ihrer Kinder für die Grundlagen der Informatik zu bewerten. Dieser Test wird uns helfen, die technologischen Fähigkeiten Ihres Kindes zu verstehen und die notwendige Unterstützung zur Förderung ihrer Fähigkeiten zu identifizieren. Wir schätzen Ihre Zusammenarbeit und garantieren die Vertraulichkeit und Sicherheit der bereitgestellten Informationen.",
      "Thank you for your participation and ongoing support.": "Vielen Dank für Ihre Teilnahme und Ihre anhaltende Unterstützung.",
      "Child Name": "Name des Kindes",
      "Please specify": "Bitte angeben",
      "This question is required": "Diese Frage ist erforderlich",
      "Thank you for contacting us, we will notify you of the test results via email or WhatsApp message.": "Vielen Dank für Ihre Kontaktaufnahme. Wir werden Sie per E-Mail oder WhatsApp-Nachricht über die Testergebnisse informieren.",
      // Footer
      "Quick Links": "Schnellzugriffe",
      "Contact us": "Kontaktiere uns",
      "Get in touch": "Kontakt aufnehmen",
      "Enable Light Mode": "Lichtmodus aktivieren",
      "Enable Dark Mode": "Dunkelmodus aktivieren",
      // SelectLanguagePopup
      "Choose Language": "Sprache wählen",
      English: "English",
      Deutsch: "Deutsch",
      Arabic: "العربية",
      // ProjectInformation
      "Web images": "Webbilder",
      "Mobile app images": "Bilder von mobilen Apps",
      // AboutUs Page
      "Empowering Innovation: Our Journey Begins":
        "Innovation fördern: Unser Weg beginnt",
      "At TECREK, we are not just a technology company; we are pioneers in the digital realm. Founded in 2023, we began as a small team with big ambitions. Today, we have grown into a diverse collective of professionals from various countries, each bringing unique degrees and expertise to our mission. We specialize in crafting smart, fast, and efficient digital solutions that streamline your business operations. Our innovative web platforms, mobile applications, and administrative panels are designed to be intuitive and user-friendly, saving you time and enhancing productivity. By simplifying complex processes, we help you focus on what truly matters—growing your business and fostering creativity among your team.":
        "Bei TECREK sind wir nicht nur ein Technologieunternehmen; wir sind Pioniere im digitalen Bereich. Gegründet im Jahr 2023, begannen wir als kleines Team mit großen Ambitionen. Heute sind wir zu einer vielfältigen Gemeinschaft von Fachleuten aus verschiedenen Ländern herangewachsen, von denen jeder einzigartige Abschlüsse und Fachkenntnisse in unsere Mission einbringt. Wir sind spezialisiert auf die Entwicklung intelligenter, schneller und effizienter digitaler Lösungen, die Ihre Geschäftsprozesse optimieren. Unsere innovativen Webplattformen, mobilen Anwendungen und administrativen Panels sind darauf ausgelegt, intuitiv und benutzerfreundlich zu sein, was Ihnen Zeit spart und die Produktivität steigert. Indem wir komplexe Prozesse vereinfachen, helfen wir Ihnen, sich auf das zu konzentrieren, was wirklich zählt – das Wachstum Ihres Unternehmens und die Förderung der Kreativität in Ihrem Team.",
      "Be yourself and belong": "Sei du selbst und gehöre dazu",
      "We foster an inclusive workplace culture, boost social participation, and advocate it outside of TECREK":
        "Wir fördern eine inklusive Arbeitsplatzkultur, steigern die soziale Teilhabe und befürworten sie außerhalb von TECREK",
      "We feel accountable for making our workspace culture inclusive, transparent, and socially responsible so everyone can realize their full potential while being their own person. We strive to make everyone feel a part of our team.":
        "Wir fühlen uns verantwortlich dafür, unsere Arbeitsplatzkultur inklusiv, transparent und sozial verantwortlich zu gestalten, damit jeder sein volles Potenzial entfalten kann, während er gleichzeitig er selbst ist. Wir bemühen uns, dass sich jeder als Teil unseres Teams fühlt.",
      "Build a career that suits your lifestyle, in a company where your voice matters.":
        "Bauen Sie eine Karriere auf, die zu Ihrem Lebensstil passt, in einem Unternehmen, in dem Ihre Stimme zählt.",
      "Shaping a Smarter Future: Our Commitment to Your Success":
        "Gestaltung einer intelligenteren Zukunft: Unser Engagement für Ihren Erfolg",
      "Our goal at TECREK is profound yet simple: to make your business operations as efficient as possible, allowing you and your employees more time to innovate and succeed. We understand that in the fast-paced world of business, efficiency is key to maintaining a competitive edge. We are committed to ongoing development and refinement of our services to ensure they not only meet but exceed your needs. Each project we undertake is a step towards building a better and more helpful world. Join us as we continue to innovate and redefine the possibilities within the digital landscape. Together, we can transform challenges into opportunities and pave the way for a brighter, more successful future.":
        "Unser Ziel bei TECREK ist tiefgreifend und dennoch einfach: Ihre Geschäftsabläufe so effizient wie möglich zu gestalten, damit Sie und Ihre Mitarbeiter mehr Zeit haben, um zu innovieren und erfolgreich zu sein. Wir verstehen, dass in der schnelllebigen Welt des Geschäftslebens Effizienz der Schlüssel zur Aufrechterhaltung eines Wettbewerbsvorteils ist. Wir sind bestrebt, unsere Dienstleistungen kontinuierlich weiterzuentwickeln und zu verbessern, um sicherzustellen, dass sie nicht nur Ihre Bedürfnisse erfüllen, sondern diese auch übertreffen. Jedes Projekt, das wir durchführen, ist ein Schritt hin zu einer besseren und hilfreicheren Welt. Begleiten Sie uns, während wir weiterhin Innovationen vorantreiben und die Möglichkeiten im digitalen Bereich neu definieren. Gemeinsam können wir Herausforderungen in Chancen verwandeln und den Weg für eine hellere und erfolgreichere Zukunft ebnen.",
      // Team
      "People are the key to success": "Menschen sind der Schlüssel zum Erfolg",
      "We feel proud every time when someone new is joining":
        "Wir sind jedes Mal stolz, wenn jemand Neues",
      // PrivacySettings 
      "Data Protection Options": "Datenschutzoptionen",
      "Our website employs third-party tracking technologies from other websites to enhance and personalize our services, as well as for advertising objectives. The installation of optional cookies is contingent upon your approval, which you can withdraw at any moment.": "Unsere Website verwendet Tracking-Technologien von Dritten, um unsere Dienste zu verbessern und anzupassen sowie für Werbezwecke. Die Installation von optionalen Cookies hängt von Ihrer Zustimmung ab, die Sie jederzeit widerrufen können.",
      "Agree": "Zustimmen",
      // ChooseContactUsWay 
      "Select how you would like to contact us.": "Wählen Sie, wie Sie uns kontaktieren möchten.",
      "Call": "Anrufen",
      "Whatsapp": "WhatsApp",
      // LoginAdmin
      "Admin Login": "Administrator-Anmeldung",
      "Email": "E-Mail",
      "Your Email": "Ihre E-Mail",
      "Password": "Passwort",
      "Your Password": "Ihr Passwort",
      "Login": "Login",
      // Dashboard
      "Course students": "Kursteilnehmer",
      "Id": "Id",
      "Name": "Name",
      "Student details": "Angaben zum Studierenden",
      "Student id": "Studierendenausweis",
      "Student name": "Name des Studierenden",
      "Father name": "Name des Vaters",
      "Mother name": "Name der Mutter",
      "Child age": "Alter des Kindes",
      "Child's guardian email": "E-Mail des Erziehungsberechtigten",
      "Child's guardian number": "Erziehungsberechtigtennummer des Kindes",
      "Test score": "Testergebnis",
      "Not tested yet": "Noch nicht getestet",
      "level":"ebene",
      // MakeDecision 
      "Are you sure you want to":"Möchten Sie sich wirklich",
      "Logout" : "ausloggen",
      "logout":"ausloggen",
      "this":"Das",
    },
  },
  Arabic: {
    translation: {
      // NavItems
      Home: "الرئيسية",
      "About Us": "من نحن",
      "Our Work": "أعمالنا",
      "Courses": "الدورات",
      "Contact Us": "اتصل بنا",
      // Landing Section
      "We Bring Your Digital Dreams to Life.": "نحقق أحلامك الرقمية",
      'After TECREK revamped our app, our sales conversion rates soared." - TECREK Customer':
        ' بعد أن قامت TECREK  بتحسين تطبيقنا، ارتفعت معدلات تحويل المبيعات لدينا - "عميل TECREK ',
      "Talk to us": "تحدث إلينا",
      // AboutUs section
      "ABOUT US": "من نحن",
      "We Will Be Your Technology Partner for Digital Transformation":
        "سنكون شريكك التكنولوجي في التحول الرقمي",
      "TECREK specializes in web and app development services with a strategic focus on database-driven solutions, including E-Commerce and Inventory Management. Established in 2023, we have become a pivotal technology ally for over 11 firms across Malaysia, Dubai, Syria, and Germany":
        "تخصص TECREK في خدمات تطوير الويب والتطبيقات مع التركيز الاستراتيجي على الحلول المدعومة بقواعد البيانات، بما في ذلك التجارة الإلكترونية وإدارة المخزون. تأسست في عام 2023، وقد أصبحت حليفًا تكنولوجيًا أساسيًا لأكثر من 11 شركة عبر ماليزيا ودبي وسوريا وألمانيا.",
      "Our mission is to enable our partner businesses to thrive in the dynamic and rapidly evolving technological landscape of today.":
        "مهمتنا هي تمكين شركائنا التجاريين من الازدهار في المشهد التكنولوجي الديناميكي والمتطور بسرعة اليوم.",
      "Our vision is to catapult our partner firms into new heights of success, adapting swiftly and effectively to the technological shifts of the modern world.":
        "رؤيتنا هي أن نطلق شركائنا في النجاح إلى آفاق جديدة، متكيفين بسرعة وفعالية مع التحولات التكنولوجية في العالم الحديث.",
      // WhatWeDo section
      "WHAT WE DO": "ما نقوم به",
      "Web Applications": "تطبيقات الويب",
      "Tailored multi-functional web platforms, designed specifically for your business needs.":
        "منصات ويب متعددة الوظائف مصممة خصيصًا لاحتياجات عملك.",
      "Mobile Apps": "تطبيقات الجوال",
      "Develop mobile applications efficiently and affordably. Available for both Android and iOS platforms.":
        "تطوير تطبيقات الجوال بكفاءة وبتكلفة معقولة. متاحة لكلا من منصات Android و iOS.",
      "Digital Marketing": "التسويق الرقمي",
      "Accelerate your success with our dynamic digital marketing solutions, tailored to maximize your online impact.":
        "قم بتسريع نجاحك من خلال حلول التسويق الرقمي الديناميكية لدينا، والمصممة خصيصًا لتحقيق أقصى قدر من التأثير على الإنترنت.",
      "Managed Social Media": "إدارة وسائل التواصل الاجتماعي",
      "Elevate your brand with our strategic social media management services, designed to boost engagement and visibility.":
        "ارفع علامتك التجارية مع خدمات إدارة وسائل التواصل الاجتماعي الاستراتيجية لدينا، المصممة لزيادة التفاعل والرؤية.",
      "E-Commerce": "التجارة الإلكترونية",
      "Enhance your online sales with our versatile ready-made and custom e-commerce solutions.":
        "عزز مبيعاتك عبر الإنترنت من خلال حلول التجارة الإلكترونية المتنوعة والجاهزة والمخصصة.",
      "API Development": "تطوير واجهات البرمجة التطبيقية (API)",
      "Create your own REST API with comprehensive documentation to support third-party integrations.":
        "إنشاء واجهة برمجة تطبيقات REST الخاصة بك مع وثائق شاملة لدعم التكامل مع الأطراف الثالثة.",
      Maintenance: "الصيانة",
      "Enjoy a hassle-free experience as we handle your web and app maintenance, allowing you to concentrate on your core business.":
        "استمتع بتجربة خالية من المتاعب حيث نقوم بإدارة صيانة موقعك الإلكتروني وتطبيقك، مما يتيح لك التركيز على أعمالك الأساسية.",
      "Web Design": "تصميم الويب",
      "Modernize your website with cutting-edge designs and the latest UX/UI principles to enhance user engagement.":
        "حدث موقع الويب الخاص بك بتصاميم حديثة ومبتكرة وبمبادئ تجربة المستخدم/واجهة المستخدم الأخيرة لتعزيز مشاركة المستخدم.",
      // OurWork section
      "OUR WORK": "أعمالنا",
      "We collaborate closely with our clients to design solutions":
        "نتعاون عن كثب مع عملائنا لتصميم حلول.",
      "Know more": "اعرف المزيد",
      "More projects": "مشاريع آخرى",
      // Reviews section
      "THE REVIEWS": "التقييمات",
      // ContactUs section
      "TALK TO US": "تواصل معنا",
      "Join us in forging your digital future":
        "انضم إلينا في صياغة مستقبلك الرقمي",
      "Whether you’re ready to begin or have inquiries, reach out to discuss how we can assist you.":
        "سواء كنت مستعدًا للبدء أو لديك استفسارات، تواصل لمناقشة كيف يمكننا مساعدتك.",
      "First Name*": "الاسم الأول",
      "Last Name*": "الاسم الأخير",
      "Phone Number*": "رقم الهاتف",
      "Email*": "البريد الإلكتروني",
      "Company Name*": "اسم الشركة",
      "Message*": "الرسالة",
      "Child Name*": "اسم الطفل*",
      "Father Name*": "اسم الأب*",
      "Family Name": "العائلة*",
      "Child Age*": "عمر الطفل*",
      "Mother Name*": "اسم الأم*",
      "Parent's Email*": "البريد الإلكتروني لولي أمر الطفل*",
      "Parent's Phone*": "رقم ولي أمر الطفل*",
      "Arabic level*": "*مستوى اللغة العربية",
      "Choose Arabic Level (Conversation)*": "اختر مستوى اللغة العربية (المحادثة)*",
      "Kurdish Level*": "*مستوى اللغة الكردية",
      "Choose Kurdish Level (Conversation)*": "اختر مستوى اللغة الكردية (المحادثة)*",
      "German Level*": "*مستوى اللغة الألمانية",
      "Choose German Level (Conversation)*": "اختر مستوى اللغة الألمانية (المحادثة)*",
      "English Level*": "*مستوى اللغة الإنكليزية",
      "Choose English Level (Conversation)*": "اختر مستوى اللغة الإنكليزية (المحادثة)*",
      "بلا مستوى" : "بلا مستوى" ,
      "ضعيف" : "ضعيف",
      "متوسط" : "متوسط",
      "جيد" : "جيد",
      "ممتاز" : "ممتاز",
      "Levelless": "بلا مستوى",
      "Weak": "ضعيف",
      "Middle": "متوسط",
      "Good": "جيد",
      "Perfect": "ممتاز",
          "Niveaulos": "بلا مستوى",
      "Schwach": "ضعيف",
      "Mitte": "متوسط",
      "Gut": "جيد",
      "Perfekt": "ممتاز",
      Send: "ارسل",
      "Ok": "حسناَ",
      "Thank you for reaching out! Your message is important to us, and we’re looking forward to helping you. One of our team members will get back to you shortly.":
        "شكرًا لتواصلك معنا! رسالتك مهمة بالنسبة لنا، ونتطلع إلى مساعدتك. سيقوم أحد أعضاء فريقنا بالرد عليك قريبًا.",
      "Thank you for contacting us. We will inform you of the registration confirmation via email or WhatsApp message if your application for the September Computer Basics course is accepted.": "شكرًا على تواصلكم معنا. سنقوم بإعلامكم بتأكيد التسجيل عبر البريد الإلكتروني أو رسالة على الواتساب في حال تم قبول طلبكم لدورة أساسيات الحاسوب لشهر سبتمبر.",
      "Next": "التالي",
      "This is your child's number": "هذا هو رقم طفلك",
      "Remember the previous number well": "تذكر الرقم السابق جيدا",
      // Course Details Page
      "Course Details": "تفاصيل الدورة",
      "Here you will find all the course details": "هنا ستجد كافة تفاصيل الدورة",
      "Course Title": "عنوان الدورة",
      "Course description": "وصف الدورة",
      "Age": "العمر",
      "Max students in class": "الحد الأقصى للطلاب في الفصل",
      "Course duration": "مدة الدورة",
      "Session duration": "مدة الجلسة",
      "Session time": "وقت الجلسة",
      "Session dates": "مواعيد الجلسة",
      "Registration limit": "حد التسجيل",
      "Cost": "التكلفة",
      "What will we learn?": "ماذا سوف نتعلم؟",
      "So": "لذا",
      "Note on registration": "ملاحظة حول التسجيل",
      "Close": "إغلاق",
      // SurveyByStudentNumber
      "We invite all parents to participate in completing 25 questions designed to deepen our understanding of your children’s personalities. Your participation is crucial in helping us tailor an educational experience that fits the unique traits and needs of each child. We guarantee complete confidentiality of all information provided and promise that it will be used solely to enhance the support we offer our students.": "ندعو كل الأهالي للمشاركة في استكمال 25 سؤالًا تهدف إلى تعميق فهمنا لشخصيات أطفالكم. تعتبر مشاركتكم ضرورية لمساعدتنا على تصميم تجربة تعليمية تتناسب مع خصائص واحتياجات كل طفل. نضمن سرية تامة لكافة المعلومات التي تقدمونها ونعدكم بأن تُستخدم لتحسين الدعم الذي نقدمه لطلابنا.",
      "Thank you for your engagement and commitment to improving education at our school.": "شكرًا لتفاعلكم واهتمامكم بتطوير مستوى التعليم في مدرستنا.",
      "We invite all parents to participate in answering 25 questions designed to assess your children’s understanding of computer basics. This test will help us understand your child’s technological capabilities and identify the support needed to enhance their skills. We appreciate your cooperation and assure you of the confidentiality and security of the information provided.": "ندعو جميع الأهالي للمشاركة بالإجابة على 25 سؤالًا مصممة لتقييم مستوى معرفة أطفالكم بأساسيات الحاسوب. هذا الاختبار سيساعدنا على فهم قدرات طفلكم التكنولوجية وتحديد الدعم اللازم لتعزيز مهاراتهم. نقدر تعاونكم ونؤكد على سرية وأمان المعلومات المقدمة.",
      "Thank you for your participation and ongoing support.": "شكرًا لمشاركتكم ودعمكم المستمر.",
      "Child Name": "اسم الطفل",
      "Please specify": "يرجى التحديد",
      "This question is required": "هذا السؤال مطلوب",
      "Thank you for contacting us, we will notify you of the test results via email or WhatsApp message.": "نشكرك على التواصل معنا، وسنقوم بإعلامك بنتائج الاختبار عبر البريد الإلكتروني أو رسالة واتساب.",
      // Footer
      "Quick Links": "روابط سريعة",
      "Contact us": "اتصل بنا",
      "Get in touch": "تواصل معنا",
      "Enable Light Mode": "تفعيل الوضع الفاتح",
      "Enable Dark Mode": "تفعيل الوضع الداكن",
      // SelectLanguagePopup
      "Choose Language": "اختر اللغة",
      English: "English",
      Deutsch: "Deutsch",
      Arabic: "العربية",
      // ProjectInformation
      "Web images": "صور الويب",
      "Mobile app images": "صور التطبيق الجوال",
      // AboutUs Page
      "Empowering Innovation: Our Journey Begins":
        "تمكين الابتكار: بداية رحلتنا",
      "At TECREK, we are not just a technology company; we are pioneers in the digital realm. Founded in 2023, we began as a small team with big ambitions. Today, we have grown into a diverse collective of professionals from various countries, each bringing unique degrees and expertise to our mission. We specialize in crafting smart, fast, and efficient digital solutions that streamline your business operations. Our innovative web platforms, mobile applications, and administrative panels are designed to be intuitive and user-friendly, saving you time and enhancing productivity. By simplifying complex processes, we help you focus on what truly matters—growing your business and fostering creativity among your team.":
        "في TECREK، نحن لسنا مجرد شركة تكنولوجيا؛ بل نحن رواد في المجال الرقمي. تأسست في عام 2023، بدأنا كفريق صغير لديه طموحات كبيرة. اليوم، نمونا إلى مجموعة متنوعة من المحترفين من مختلف البلدان، كل منهم يجلب درجات فريدة وخبرة إلى مهمتنا. نحن متخصصون في صياغة حلول رقمية ذكية وسريعة وفعالة تعمل على تيسير عمليات عملك. منصات الويب الابتكارية لدينا، وتطبيقات الجوال، ولوحات الإدارة مصممة لتكون سهلة الاستخدام وودية للمستخدم، مما يوفر لك الوقت ويعزز الإنتاجية. من خلال تبسيط العمليات المعقدة، نساعدك على التركيز على ما هو حقا مهم - نمو عملك وتعزيز الإبداع بين فريقك.",
      "Be yourself and belong": "كن نفسك وانتمي",
      "We foster an inclusive workplace culture, boost social participation, and advocate it outside of TECREK":
        "نحن نعزز ثقافة العمل شاملة، ونعزز المشاركة الاجتماعية، ونؤيدها خارج TECREK",
      "We feel accountable for making our workspace culture inclusive, transparent, and socially responsible so everyone can realize their full potential while being their own person. We strive to make everyone feel a part of our team.":
        "نحن نشعر بالمسؤولية عن جعل ثقافة مكان العمل لدينا شاملة وشفافة ومسؤولة اجتماعيا بحيث يمكن للجميع تحقيق إمكاناتهم الكاملة بينما يكونون أنفسهم. نحن نسعى لجعل الجميع يشعرون بأنهم جزء من فريقنا.",
      "Build a career that suits your lifestyle, in a company where your voice matters.":
        "ابنِ مستقبلًا يتناسب مع نمط حياتك، في شركة تهتم برأيك.",
      "Shaping a Smarter Future: Our Commitment to Your Success":
        "صياغة مستقبل أذكى: التزامنا بنجاحك",
      "Our goal at TECREK is profound yet simple: to make your business operations as efficient as possible, allowing you and your employees more time to innovate and succeed. We understand that in the fast-paced world of business, efficiency is key to maintaining a competitive edge. We are committed to ongoing development and refinement of our services to ensure they not only meet but exceed your needs. Each project we undertake is a step towards building a better and more helpful world. Join us as we continue to innovate and redefine the possibilities within the digital landscape. Together, we can transform challenges into opportunities and pave the way for a brighter, more successful future.":
        "هدفنا في TECREK عميق وبسيط في الوقت نفسه: جعل عمليات عملك أكثر كفاءة قدر الإمكان، مما يتيح لك ولموظفيك المزيد من الوقت للابتكار والنجاح. نحن ندرك أن الكفاءة هي العنصر الأساسي للحفاظ على القدرة التنافسية في عالم الأعمال السريع الخطى. نحن ملتزمون بالتطوير المستمر والتحسين المستمر لخدماتنا لضمان أنها لا تلبي فقط احتياجاتك ولكن تتجاوزها. كل مشروع نقوم به هو خطوة نحو بناء عالم أفضل وأكثر فائدة. انضم إلينا ونحن نستمر في الابتكار وإعادة تعريف الإمكانيات في المشهد الرقمي. معًا، يمكننا تحويل التحديات إلى فرص وفتح الطريق نحو مستقبل أكثر إشراقًا ونجاحًا.",
      // Team
      "People are the key to success": "الأشخاص الذين هم سر النجاح",
      "We feel proud every time when someone new is joining":
        "نحن نشعر بالفخر في كل مرة ينضم فيها شخص جديد",
      // PrivacySettings 
      "Data Protection Options": "إعدادات حماية الخصوصية",
      "Our website employs third-party tracking technologies from other websites to enhance and personalize our services, as well as for advertising objectives. The installation of optional cookies is contingent upon your approval, which you can withdraw at any moment.": "يستخدم موقعنا الإلكتروني تقنيات تتبع تابعة لجهات خارجية من مواقع ويب أخرى لتحسين خدماتنا وتخصيصها، وكذلك لأغراض إعلانية. ويتوقف تثبيت ملفات تعريف الارتباط الاختيارية على موافقتك، والتي يمكنك سحبها في أي وقت.",
      "Agree": "الموافقة",
      // ChooseContactUsWay 
      "Select how you would like to contact us.": "ما هي الطريقة التي تفضلها للتواصل معنا",
      "Call": "مكالمة هاتفية",
      "Whatsapp": "واتساب",
      // LoginAdmin
      "Admin Login": "تسجيل الدخول كمسؤول",
      "Email": "البريد الإلكتروني",
      "Your Email": "بريدك الإلكتروني",
      "Password": "كلمة المرور",
      "Your Password": "كلمة المرور",
      "Login": "تسجيل دخول",
      // Dashboard
      "Course students": "طلاب الكورس",
      "Id": "الرقم",
      "Name": "الاسم",
      "Student details": "تفاصيل الطالب",
      "Student id": "الرقم التسلسلي",
      "Student name": "اسم الطالب",
      "Father name": "اسم الأب",
      "Mother name": "اسم الأم",
      "Child age": "عمر الطفل",
      "Child's guardian email": "البريد الإلكتروني لولي أمر الطفل",
      "Child's guardian number": "رقم ولي أمر الطفل",
      "Test score": "نتيجة الاختبار",
      "Not tested yet": "لم يختبر بعد",
      "level":"مستوى",
      // MakeDecision 
      "Are you sure you want to":"هل أنت متأكد أنك تريد",
      "Logout" : "تسجيل الخروج",
      "logout":"تسجيل الخروج",
      "this":"هذا",
    },
  },
};
const lng = localStorage.getItem("language") || "English"; // Fallback to English if no language is set
i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
