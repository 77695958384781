import React, { useContext } from "react";
import { useTheme } from "../../Context/ThemeContext";
import { PopupContext } from "../../Context/PopupContext";
import americaFlag from "../../assets/Flags/americaFlag.png";
import germanyFlag from "../../assets/Flags/germanyFlag.png";
import saudiArabiaFlag from "../../assets/Flags/saudiArabiaFlag.png";

function LanguageButton({ className, navMode, mobMode, footMode }) {
  const selectedLanguage = localStorage.getItem("language") || "English";
  const { theme } = useTheme();
  const { setLanguageOpen } = useContext(PopupContext);
  return mobMode || footMode ? (
    <div
      onClick={() => setLanguageOpen(true)}
      className={`${
        theme === "dark"
          ? "text-MainTextColorDM bg-[#535353] hover:bg-[#363636]"
          : "text-MainTextColor bg-[#c3c3c7]  hover:bg-[#a4a4a6]"
      } ${
        navMode ? "min-w-[100px]" : "w-[130px]"
      } ${className} duration-300 flex items-center justify-between my-2 py-1 px-4 cursor-pointer rounded-[60px]`}
    >
      <h1>{navMode ? selectedLanguage.slice(0, 2) : selectedLanguage}</h1>
      <img
        className="w-[28px] h-[20px] rounded-sm cursor-pointer"
        src={
          selectedLanguage === "Arabic"
            ? saudiArabiaFlag
            : selectedLanguage === "English"
            ? americaFlag
            : germanyFlag
        }
        alt=""
      />
    </div>
  ) : (
    <img
      onClick={() => setLanguageOpen(true)}
      className="w-[28px] h-[20px] rounded-sm cursor-pointer"
      src={
        selectedLanguage === "Arabic"
          ? saudiArabiaFlag
          : selectedLanguage === "English"
          ? americaFlag
          : germanyFlag
      }
      alt=""
    />
  );
}

export default LanguageButton;
