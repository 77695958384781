import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const SuccessToast = (Msg) => {
  toast.success(Msg, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
  });
};

export const ErrorToast = (Err) => {
  toast.error(Err, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
  });
};

export const LoadingToast = (Msg) => {
  const toastId = toast.loading(Msg, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
  });
  return toastId; // Return the toast ID
};
