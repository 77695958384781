import React, { useEffect } from "react";
import NavBar from "../Components/NavBar";
import Container from "../Components/Container";
import AboutUsItem from "../Components/AboutUsItem";
import AboutUsText from "../Components/AboutUsText";
import Team from "../Components/Team";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import ScrollTopButton from "../Components/ScrollTopButton";

function AboutUs() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Container>
        <NavBar />
        <div className="flex flex-col gap-5 max-xl:gap-9">
          <AboutUsItem
            className="flex-row max-xl:flex-col pt-[60px] max-md:p-[10px] max-md:my-[10px]"
            title={t("Empowering Innovation: Our Journey Begins")}
            description={t(
              "At TECREK, we are not just a technology company; we are pioneers in the digital realm. Founded in 2023, we began as a small team with big ambitions. Today, we have grown into a diverse collective of professionals from various countries, each bringing unique degrees and expertise to our mission. We specialize in crafting smart, fast, and efficient digital solutions that streamline your business operations. Our innovative web platforms, mobile applications, and administrative panels are designed to be intuitive and user-friendly, saving you time and enhancing productivity. By simplifying complex processes, we help you focus on what truly matters—growing your business and fostering creativity among your team."
            )}
            image="https://backend.tecrek.com/public/frontend/2.webp"
          />
          <AboutUsItem
            className="flex-row-reverse max-xl:flex-col pt-[60px] max-md:p-[10px] max-md:my-[10px]"
            title={t("Be yourself and belong")}
            description={t(
              "We foster an inclusive workplace culture, boost social participation, and advocate it outside of TECREK"
            )}
            content={
              <div className="flex flex-col gap-2">
                <AboutUsText
                  text={t(
                    "We feel accountable for making our workspace culture inclusive, transparent, and socially responsible so everyone can realize their full potential while being their own person. We strive to make everyone feel a part of our team."
                  )}
                />
                <AboutUsText
                  text={t(
                    "Build a career that suits your lifestyle, in a company where your voice matters."
                  )}
                />
              </div>
            }
            image="https://backend.tecrek.com/public/frontend/3.webp"
          />
          <AboutUsItem
            className="flex-row max-xl:flex-col pt-[60px] max-md:p-[10px] max-md:my-[10px]"
            title={t(
              "Shaping a Smarter Future: Our Commitment to Your Success"
            )}
            description={t(
              "Our goal at TECREK is profound yet simple: to make your business operations as efficient as possible, allowing you and your employees more time to innovate and succeed. We understand that in the fast-paced world of business, efficiency is key to maintaining a competitive edge. We are committed to ongoing development and refinement of our services to ensure they not only meet but exceed your needs. Each project we undertake is a step towards building a better and more helpful world. Join us as we continue to innovate and redefine the possibilities within the digital landscape. Together, we can transform challenges into opportunities and pave the way for a brighter, more successful future."
            )}
            image="https://backend.tecrek.com/public/frontend/4.webp"
          />
        </div>
        <Team />
        <Footer />
      </Container>
      <ScrollTopButton />
    </div>
  );
}

export default AboutUs;
