import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
function ProductSkeleton({ className }) {
  return (
    <div
      className={`${className} mt-[30px] flex items-center relative gap-8 max-md:flex-col-reverse max-md:items-start`}
    >
      <div className="flex justify-center basis-[60%] z-10 md:mr-7">
        <div className="flex flex-col gap-3">
          <Skeleton width={100} className="h-[45px] rounded-xl" />
          <Skeleton width={130} className="h-[45px] rounded-xl" />
          <Skeleton width={200} className="h-[50px] rounded-xl" />
          <Skeleton width={100} className="h-[42px] rounded-xl" />
        </div>
      </div>
      <div className="basis-[40%] w-[300px] h-[300px] max-md:w-full max-md:max-w-[300px] max-md:max-h-[200px] z-10 rounded-xl max-md:mx-auto">
        <Skeleton className="w-full h-full max-md:w-[300px] max-md:h-[150px]" />
      </div>
    </div>
  );
}

export default ProductSkeleton;
