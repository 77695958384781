import "./App.css";
import Home from "./Website/Pages/Home/Home";
import ProjectDetails from "./Website/Pages/ProjectDetails";
import { useTheme } from "./Context/ThemeContext";
import AboutUs from "./Website/Pages/AboutUs";
import OurWork from "./Website/Pages/OurWork";
import { SkeletonTheme } from "react-loading-skeleton";
import { useContext, useEffect } from "react";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import { PopupContext } from "./Context/PopupContext";
import { useTranslation } from "react-i18next";
import Courses from "./Website/Pages/Courses";
import CourseDetails from "./Website/Pages/CourseDetails";
import SurveyByStudentNumber from "./Website/Pages/SurveyByStudentNumber";
import ComputerSurveyByStudentNumber from "./Website/Pages/ComputerSurveyByStudentNumber";
import Cookies from "js-cookie";
import LoginAdmin from "./Admin/Pages/LoginAdmin";
import Dashboard from "./Admin/Pages/Dashboard";


function App() {
  const adminToken = Cookies.get("tecrekAdminToken")
  const lng = localStorage.getItem("language") || "English";
  const {i18n} = useTranslation()
  const { theme } = useTheme();
  const { dir, myLang, setDir } = useContext(PopupContext)
  
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/aboutUs",
    element: <AboutUs />,
  },
  {
    path: "/ourWork",
    element: <OurWork />,
  },
  {
    path: "/projects/:projectId",
    element: <ProjectDetails />,
  },
  {
    path: "/courses",
    element: <Courses/>,
  },
  {
    path: "/courseDetails/1",
    element: <CourseDetails/>,
  },
  {
    path: "/survey/:studentNumber",
    element: <SurveyByStudentNumber/>,
  },
  {
    path: "/ComputerSurvey/:studentNumber",
    element: <ComputerSurveyByStudentNumber/>,
  },
  {
    path: "/admin",
    element: <LoginAdmin/>,
  },
  {
    path: "/dashboard",
    element: adminToken ? <Dashboard/> : <Navigate to={`/`}/>,
  },
]);

  useEffect(() => {
    document.body.dir = dir;
    setDir(lng === "Arabic" ? "rtl" : "ltr")
    i18n.changeLanguage(lng === "Arabic" ? "Arabic" : lng === "English" ? "English":"Deutsch")
  }, [dir,lng,myLang]);

  return (
    <div
      className={`${
        theme === "dark"
          ? "bg-MainBgColorDM text-MainTextColorDM"
          : "bg-MainBgColor text-MainTextColor"
      } h-full`}
    >
      <SkeletonTheme
        baseColor={`${theme === "dark" ? "#202020" : "#F5F5F5"}`}
        highlightColor={`${theme === "dark" ? "#444" : "#E0E0E0"}`}
      >
        <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
}

export default App;
