import React from "react";
import { useTheme } from "../../../../Context/ThemeContext";
import { useTranslation } from "react-i18next";
function AboutUs() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  return (
    <div
      id="about_us"
      className={`${
        theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
      } pt-[60px] max-md:p-[10px] max-md:my-[30px] max-sm:-mb-[30px]`}
    >
      <div className="md:mb-[30px]">
        <div className="flex flex-col font-semibold">
          <h1
            className={`text-[18px] w-fit bg-gradient-to-r from-SecondTextColor ${
              theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
            } text-transparent bg-clip-text`}
          >
            {t("ABOUT US")}
          </h1>
          <h2 className="leading-[52px] text-[48px] max-xl:text-[40px] max-md:text-[22px] max-md:leading-[32px]">
            {t("We Will Be Your Technology Partner for Digital Transformation")}
          </h2>
        </div>
      </div>
      <div className="flex max-lg:flex-col-reverse relative gap-8">
        <div className="lg:basis-[45%] rounded-tl-[100px] rounded-tr-[20px] rounded-bl-[20px] overflow-hidden max-lg:max-w-[500px] max-lg:max-h-[500px] max-lg:mx-auto">
          <img
            className="w-full h-full"
            src={`https://backend.tecrek.com/public/frontend/1.webp`}
            alt=""
          />
        </div>
        <div className="lg:basis-[55%] max-md:mt-5">
          <div className="md:text-text18 max-md:text-text16 flex flex-col gap-3">
            <div>
              {t(
                "TECREK specializes in web and app development services with a strategic focus on database-driven solutions, including E-Commerce and Inventory Management. Established in 2023, we have become a pivotal technology ally for over 11 firms across Malaysia, Dubai, Syria, and Germany"
              )}
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <p>
                  {t(
                    "Our mission is to enable our partner businesses to thrive in the dynamic and rapidly evolving technological landscape of today."
                  )}
                </p>
              </div>
              <div className="flex items-center gap-2">
                <p>
                  {t(
                    "Our vision is to catapult our partner firms into new heights of success, adapting swiftly and effectively to the technological shifts of the modern world."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
