import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function ReviewItemSkeleton(className, index) {
  return (
    <div
      key={index}
      className={`${className} flex flex-col gap-2 max-md:mb-[20px] max-w-[600px]`}
    >
      <Skeleton height={40} width={130} />
      <Skeleton height={200} />
      <div className="flex items-center gap-4">
        <Skeleton height={120} width={120} circle />
        <Skeleton height={40} width={130} />
      </div>
    </div>
  );
}

export default ReviewItemSkeleton;
