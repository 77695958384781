import React from "react";
import ReactPaginate from "react-paginate";

function Pagination({ totalPages, currentPage, itemPerPage, handlePageClick }) {
  return (
    totalPages !== 1 && (
      <ReactPaginate
        initialPage={currentPage - 1}
        previousLabel={"<<"}
        nextLabel={">>"}
        breakLabel={"..."}
        pageCount={totalPages}
        perPage={itemPerPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={"flex flex-wrap items-center gap-1"}
        activeLinkClassName={
          "py-[7.5px] px-[12px] cursor-default border-transparent hover:border-transparent bg-black text-white rounded-xl"
        }
        previousLinkClassName={
          "py-[7.5px] px-[12px] border border-gray-800 bg-white hover:bg-gray-200 rounded-xl duration-300"
        }
        nextLinkClassName={
          "py-[7.5px] px-[12px] border border-gray-800 bg-white hover:bg-gray-200 rounded-xl duration-300"
        }
        pageLinkClassName={
          "py-[7.5px] px-[12px] border border-gray-800 bg-white hover:bg-gray-200 rounded-xl duration-300"
        }
        renderOnZeroPageCount={null}
      />
    )
  );
}

export default Pagination;
