import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";
import { PopupContext } from "../../Context/PopupContext";
import LanguageButton from "./LanguageButton";
import { MdLanguage } from "react-icons/md";
import logoLM from "../../assets/Logo/logoLM.png";
import logoDM from "../../assets/Logo/logoDM.png";
import { useTranslation } from "react-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";

function NavItems({ className, itemClassName, showLink }) {
  const { theme } = useTheme();
  const { setSideBarOpen } = useContext(PopupContext);
  const { t } = useTranslation();
  const lng = localStorage.getItem("language");
  return (
    <div
      className={`${className} ${
        theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
      } text-text18`}
    >
      <AiOutlineClose
        className={`${
          lng === "Arabic" ? "left-[10px]" : "right-[10px]"
        } fixed cursor-pointer top-[10px] md:hidden`}
        onClick={() => setSideBarOpen(false)}
        size={20}
      />
      <Link
        to="/"
        className={`py-2 md:hidden`}
        onClick={() => setSideBarOpen(false)}
      >
        <div className="w-[150px] h-[40px]">
          <img
            className="w-full h-full object-contain"
            src={theme === "dark" ? logoDM : logoLM}
            alt="My Logo"
          />
        </div>
      </Link>
      <Link
        className={`${itemClassName} hover:text-SecondTextColor duration-300`}
        to="/"
        onClick={() => setSideBarOpen(false)}
      >
        {t("Home")}
      </Link>
      <div
        className={`${
          theme === "dark" ? "bg-MainBgColor" : "bg-MainBgColorDM"
        } w-[100px] h-[1px] md:hidden`}
      ></div>
      <Link
        className={`${itemClassName} hover:text-SecondTextColor duration-300`}
        to="/aboutUs"
        onClick={() => setSideBarOpen(false)}
      >
        {t("About Us")}
      </Link>
      <div
        className={`${
          theme === "dark" ? "bg-MainBgColor" : "bg-MainBgColorDM"
        } w-[100px] h-[1px] md:hidden`}
      ></div>
      <Link
        className={`${itemClassName} hover:text-SecondTextColor duration-300`}
        to="/ourWork"
        onClick={() => setSideBarOpen(false)}
      >
        {t("Our Work")}
      </Link>
      <div
        className={`${
          theme === "dark" ? "bg-MainBgColor" : "bg-MainBgColorDM"
        } w-[100px] h-[1px] md:hidden`}
      ></div>
      <Link
        className={`${itemClassName} hover:text-SecondTextColor duration-300`}
        to="/courseDetails/1"
        onClick={() => setSideBarOpen(false)}
      >
        {t("Courses")}
      </Link>
      <div
        className={`${
          theme === "dark" ? "bg-MainBgColor" : "bg-MainBgColorDM"
        } w-[100px] h-[1px] md:hidden`}
      ></div>
      {showLink && (
        <>
          <Link
            onClick={() => {
              window.scrollTo({
                top: document.getElementById("contact_us").offsetTop,
                behavior: "smooth",
              });
              setSideBarOpen(false);
            }}
            className="hover:text-SecondTextColor duration-300 w-fit"
          >
            {t("Contact Us")}
          </Link>
        </>
      )}
      <div className={`md:hidden pl-1`}>
        <LanguageButton mobMode={true} />
      </div>
      <div className="max-md:hidden">
        <LanguageButton
          className="max-w-[80px]"
          icon={<MdLanguage />}
          navMode={true}
        />
      </div>
    </div>
  );
}

export default NavItems;
