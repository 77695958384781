import React, { useEffect, useState } from "react";
import { useTheme } from "../../../../Context/ThemeContext";
import { getReviews } from "../../../../APIs/api";
import ReviewItem from "../../../Components/ReviewItem";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import ReviewItemSkeleton from "../../../Components/SkeletonLoading/ReviewItemSkeleton";

function Reviews() {
  const { theme } = useTheme();
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const lng = localStorage.getItem("language") || "English";
  const { t } = useTranslation();

  useEffect(() => {
    const fetchReviewsData = async () => {
      try {
        setLoading(true);
        const response = await getReviews();
        console.log(response);
        setReviews(response);
        setLoading(false);
      } catch (err) {
        console.error(`Error fetching reviews err :`, err);
      }
    };
    fetchReviewsData();
  }, []);
  return (
    <div
      id="our_work"
      className={`${
        theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
      } pt-[60px] max-md:p-[10px] max-sm:-mb-[30px]`}
    >
      <div>
        <div className="flex flex-col font-semibold">
          <h1
            className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${
              theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
            } text-transparent bg-clip-text`}
          >
            {t("THE REVIEWS")}
          </h1>
          <div style={{ direction: "ltr" }} className={`mySwiper`}>
            <Swiper
              modules={[Pagination, Navigation]}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation
              breakpoints={{
                1280: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 1,
                },
              }}
            >
              {loading
                ? Array.from({ length: 4 }).map((_, index) => (
                    <SwiperSlide key={index}>
                      <ReviewItemSkeleton />
                    </SwiperSlide>
                  ))
                : reviews &&
                  reviews.map((review) => {
                    return (
                      <SwiperSlide key={review.id}>
                        <ReviewItem
                          logo={review.product.logo}
                          image={review.url_image}
                          name={review.name.split("/")[0]}
                          company={review.name.split("/")[1]}
                          note={
                            lng === "English"
                              ? review.note
                              : lng === "Deutsch"
                              ? review.note_gr
                              : review.note_ar
                          }
                        />
                      </SwiperSlide>
                    );
                  })}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
