import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function AboutUsItem({ className, title, description, content, image }) {
  const { theme } = useTheme();
  return (
    <div className={`${className} flex gap-7 items-center`}>
      <div className="basis-[50%] flex flex-col my-auto py-5">
        <h1
          className={`bg-gradient-to-r from-SecondTextColor ${
            theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
          } text-transparent bg-clip-text text-[24px] max-xl:text-[22px] max-md:text-[20px] mb-3 font-bold `}
        >
          {title}
        </h1>
        <h2 className="md:text-text18 max-md:text-text16 font-semibold mb-3">
          {description}
        </h2>
        <div className="md:text-text18 max-md:text-text16">{content}</div>
      </div>
      <div className="basis-[50%] overflow-hidden rounded-2xl max-h-[500px] max-xl:max-w-[500px] max-xl:mx-auto">
        <img src={image} alt="" />
      </div>
    </div>
  );
}

export default AboutUsItem;
