import React from "react";
import MainButton from "./MainButton";
import { useTranslation } from "react-i18next";

function Product({
  className,
  image,
  circles,
  circlesClassName,
  id,
  name,
  type,
  mainDescription,
}) {
  const { t } = useTranslation();
  return (
    <div
      className={`${className} flex md:items-center relative gap-8 mb-[30px] max-md:flex-col-reverse`}
    >
      <div className={`${circlesClassName} z-0 absolute`}>
        <img className="w-full h-full" src={circles} alt="" />
      </div>
      <div className="flex md:justify-center basis-[60%] z-10 ml-[40px] max-md:ml-0">
        <div className="flex flex-col">
          <h1 className="text-[30px] max-md:text-[24px]">{name}</h1>
          <h2 className="text-[28px] max-md:text-[20px]">{type}</h2>
          <p className="text-text18 max-md:text-text16 my-4 max-w-[400px]">
            {mainDescription}
          </p>
          <MainButton
            className="w-fit"
            content={t("Know more")}
            link={`/projects/${id}`}
          />
        </div>
      </div>
      <div className="basis-[40%] z-10 max-md:max-w-[400px] max-md:max-h-[400px] max-md:mx-auto">
        <img className="w-full h-full" src={image} alt="" />
      </div>
    </div>
  );
}

export default Product;
