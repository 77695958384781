import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function Popup({
  toggleSwitch,
  onClick,
  className,
  children,
  zIndex,
  noDarkMode,
}) {
  const { theme } = useTheme();
  return (
    <>
      {toggleSwitch && (
        <>
          <div
            className={`${
              !noDarkMode && theme === "dark"
                ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
            } duration-300 transition-all fixed animate-flashing ${className}`}
          >
            {children}
          </div>
          <div
            onClick={onClick}
            className={`fixed top-0 left-0 z-20 bg-black/50 w-screen h-screen animate-flashing ${zIndex}`}
          ></div>
        </>
      )}
    </>
  );
}

export default Popup;
