import React, { useContext } from "react";
import { Link } from "react-router-dom";
import NavItems from "./NavItems";
import { IoMenuOutline } from "react-icons/io5";
import logoLM from "../../assets/Logo/logoLM.png";
import logoDM from "../../assets/Logo/logoDM.png";
import { useTheme } from "../../Context/ThemeContext";
import { PopupContext } from "../../Context/PopupContext";
import Popup from "./Popup";

function NavBar({ showLink }) {
  const { theme } = useTheme();
  const lng = localStorage.getItem("language");
  const { sideBarOpen, setSideBarOpen } = useContext(PopupContext);
  return (
    <div
      className={`${
        theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
      } flex justify-between items-center h-[70px] relative`}
    >
      <Link to="/" className="py-2">
        <div className="w-[150px] h-[40px]">
          <img
            className="w-full h-full object-contain"
            src={theme === "dark" ? logoDM : logoLM}
            alt="My Logo"
          />
        </div>
      </Link>
      <div
        onClick={() => {
          setSideBarOpen(!sideBarOpen);
        }}
        className={`${
          theme === "dark" ? "hover:bg-gray-700" : "hover:bg-gray-300"
        } md:hidden cursor-pointer rounded-full p-2 duration-300`}
      >
        <IoMenuOutline
          color={theme === "dark" ? "MainTextColorDM" : "MainTextColor"}
          size={25}
        />
      </div>
      <Popup
        toggleSwitch={sideBarOpen}
        children={
          <NavItems
            itemClassName={`${
              theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
            } duration-300 py-1 pl-1 rounded-xl md:text-start md:w-full`}
            className={`${
              theme === "dark"
                ? "bg-gradient-to-br from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM"
                : "bg-gradient-to-br from-MainBgColor via-[#dadae4] to-MainBgColor"
            } w-full h-full flex flex-col gap-2 px-3`}
          />
        }
        className={`${
          sideBarOpen
            ? "right-0 opacity-100 overflow-y-hidden"
            : "-right-full opacity-0"
        } top-0 duration-300 z-30 w-full h-full md:hidden ${
          lng === "Arabic" ? "animate-slideFromLeft" : "animate-slideFromRight"
        }`}
        onClick={() => {
          setSideBarOpen(false);
        }}
        showLink={showLink}
      />

      <NavItems
        className="flex items-center gap-[1.5rem] max-md:hidden"
        showLink={showLink}
      />
    </div>
  );
}

export default NavBar;
