import React, { useEffect, useState } from "react";
import { HiChevronDoubleUp } from "react-icons/hi";
import { useTheme } from "../../Context/ThemeContext";

function ScrollTopButton({ className }) {
  const { theme } = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    isVisible && (
      <div
        onClick={scrollToTop}
        className={`${className} ${
          theme === "dark"
            ? "bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM text-MainTextColorDM border-t-MainTextColorDM border-gray-400 hover:border-MainTextColorDM"
            : "bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor text-MainTextColor border-t-MainTextColor border-gray-500 hover:border-MainTextColor"
        } z-10 fixed cursor-pointer bottom-4 max-md:bottom-10 right-4 font-bold p-3 rounded-full hover:-translate-y-[5px] border border-b-[2px] duration-300`}
      >
        <HiChevronDoubleUp size={20} />
      </div>
    )
  );
}

export default ScrollTopButton;
