import React, { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  // Initialize theme state from local storage or default to "light"
  const [theme, setTheme] = useState(() => {
    const savedTheme = window.localStorage.getItem("theme");
    return savedTheme ? savedTheme : "light";
  });

  // Update local storage whenever the theme state changes
  useEffect(() => {
    window.localStorage.setItem("theme", theme);
  }, [theme]);

  // Set theme based on time of day only if the theme is not already set in local storage
  useEffect(() => {
    if (theme === "light") {
      // Assuming "light" is the initial value
      const currentHour = new Date().getHours();
      const isNight = currentHour >= 18 || currentHour <= 6;
      setTheme(isNight ? "dark" : "light");
    }
  }, []); // Empty dependency array ensures this runs only on mount

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
