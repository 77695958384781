import React from "react";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "../../../Context/ThemeContext";
import { useTranslation } from "react-i18next";

function CourseDetailsSkeleton() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <div className="flex flex-col gap-5 max-md:-mb-5 max-md:p-[10px]">
      <div>
        <div className="flex flex-col font-semibold">
          <Skeleton width={180} height={30} />
          <Skeleton width={`100%`} height={50} />
        </div>
      </div>
      <Skeleton width={180} height={30} />
      <Skeleton width={230} height={30} />
      <table
        className={`${
          theme === "dark"
            ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
            : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
        } rounded-xl border text-text18 max-md:text-text16 max-md:hidden`}
      >
        <thead>
          <tr>
            <th
              className={`${
                theme === "dark" ? "border-gray-600" : "border-gray-800"
              } border px-3 py-2 max-md:px-2 max-md:py-1`}
            >
              {t("Age")}
            </th>
            <th
              className={`${
                theme === "dark" ? "border-gray-600" : "border-gray-800"
              } border px-3 py-2 max-md:px-2 max-md:py-1`}
            >
              {t("Max students in class")}
            </th>
            <th
              className={`${
                theme === "dark" ? "border-gray-600" : "border-gray-800"
              } border px-3 py-2 max-md:px-2 max-md:py-1`}
            >
              {t("Course duration")}
            </th>
            <th
              className={`${
                theme === "dark" ? "border-gray-600" : "border-gray-800"
              } border px-3 py-2 max-md:px-2 max-md:py-1`}
            >
              {t("Session duration")}
            </th>
            <th
              className={`${
                theme === "dark" ? "border-gray-600" : "border-gray-800"
              } border px-3 py-2 max-md:px-2 max-md:py-1`}
            >
              {t("Session time")}
            </th>
            <th
              className={`${
                theme === "dark" ? "border-gray-600" : "border-gray-800"
              } border px-3 py-2 max-md:px-2 max-md:py-1`}
            >
              {t("Session dates")}
            </th>
            <th
              className={`${
                theme === "dark" ? "border-gray-600" : "border-gray-800"
              } border px-3 py-2 max-md:px-2 max-md:py-1`}
            >
              {t("Registration limit")}
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 8 }).map((_, inIndex) => (
            <td
              key={inIndex}
              className={`${inIndex > 3 ? "max-md:hidden" : ""}`}
            >
              <Skeleton width={"100%"} height={80} />
            </td>
          ))}
        </tbody>
      </table>
      <table
        className={`${
          theme === "dark"
            ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
            : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
        } rounded-xl border text-text18 max-md:text-text16 md:hidden`}
      >
        <tr>
          <th
            className={`${
              theme === "dark" ? "border-gray-600" : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1`}
          >
            {t("Age")}
          </th>
          <td
            className={`${
              theme === "dark" ? "border-gray-600 " : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-full h-full`}
          >
            <Skeleton height={40} />
          </td>
        </tr>
        <tr>
          <th
            className={`${
              theme === "dark" ? "border-gray-600" : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1`}
          >
            {t("Max students in class")}
          </th>
          <td
            className={`${
              theme === "dark" ? "border-gray-600 " : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-full h-full`}
          >
            <Skeleton height={40} />
          </td>
        </tr>
        <tr>
          <th
            className={`${
              theme === "dark" ? "border-gray-600" : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1`}
          >
            {t("Course duration")}
          </th>
          <td
            className={`${
              theme === "dark" ? "border-gray-600 " : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-full h-full`}
          >
            <Skeleton height={40} />
          </td>
        </tr>
        <tr>
          <th
            className={`${
              theme === "dark" ? "border-gray-600" : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1`}
          >
            {t("Session duration")}
          </th>
          <td
            className={`${
              theme === "dark" ? "border-gray-600 " : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-full h-full`}
          >
            <Skeleton height={40} />
          </td>
        </tr>
        <tr>
          <th
            className={`${
              theme === "dark" ? "border-gray-600" : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1`}
          >
            {t("Session time")}
          </th>
          <td
            className={`${
              theme === "dark" ? "border-gray-600 " : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-full h-full`}
          >
            <Skeleton height={40} />
          </td>
        </tr>
        <tr>
          <th
            className={`${
              theme === "dark" ? "border-gray-600" : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1`}
          >
            {t("Session dates")}
          </th>
          <td
            className={`${
              theme === "dark" ? "border-gray-600 " : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-full h-full`}
          >
            <Skeleton height={40} />
          </td>
        </tr>
        <tr>
          <th
            className={`${
              theme === "dark" ? "border-gray-600" : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1`}
          >
            {t("Registration limit")}
          </th>
          <td
            className={`${
              theme === "dark" ? "border-gray-600 " : "border-gray-800"
            } border px-3 py-2 max-md:px-2 max-md:py-1 text-center w-full h-full`}
          >
            <Skeleton height={40} />
          </td>
        </tr>
      </table>
      <Skeleton width={180} height={30} />
      <div className="min-h-[300px] max-h-[350px]">
        <Skeleton width={`100%`} className="min-h-[300px] max-h-[350px]" />
      </div>
      <Skeleton width={`100%`} height={30} />
      <Skeleton width={`100%`} height={30} />
    </div>
  );
}

export default CourseDetailsSkeleton;
