import React, { useState } from "react";
import map from "../../../../assets/ContactUs/map.png";
import MainButton from "../../../Components/MainButton";
import { postContactUs, registerInCourse } from "../../../../APIs/api";
import { toast } from "react-toastify";
import { useTheme } from "../../../../Context/ThemeContext";
import Popup from "../../../Components/Popup";
import { useTranslation } from "react-i18next";
import { LoadingToast } from "../../../Components/ToastifyNotification/Toast";

function ContactUs({ courseRegister, courseId }) {
  const lng = localStorage.getItem("language");
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [studentNumber, setStudentNumber] = useState("");
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const initialFormState = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    compony: "",
    subject: "",
  };
  const [isAnyInputEmpty, setIsAnyInputEmpty] = useState({
    first_name: false,
    last_name: false,
    phone: false,
    email: false,
    compony: false,
    subject: false,
  });
  const initialFormCourseState = {
    child_name: "",
    father_name: "",
    family_name: "",
    child_age: "",
    mother_name: "",
    father_email: "",
    father_phone: "",
    arabic_level: "",
    Kurdish_level: "",
    german_level: "",
    english_level: "",
  };
  const [isAnyInputsCourseEmpty, setIsAnyInputsCourseEmpty] = useState({
    child_name: false,
    father_name: false,
    family_name: false,
    child_age: false,
    mother_name: false,
    father_email: false,
    father_phone: false,
    arabic_level: false,
    Kurdish_level: false,
    german_level: false,
    english_level: false,
  });

  const handleChange = (e) => {
    // Allow only digits to be entered
    if (e.target.name === "phone") {
      courseRegister
        ? setFormCourse({
          ...formCourse,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
        })
        : setForm({
          ...form,
          [e.target.name]: e.target.value.replace(/[^0-9]/g, ""),
        });
    } else {
      courseRegister
        ? setFormCourse({ ...formCourse, [e.target.name]: e.target.value })
        : setForm({ ...form, [e.target.name]: e.target.value });
    }
    const isEmpty = e.target.value === "";
    courseRegister
      ? setIsAnyInputsCourseEmpty((prevState) => ({
        ...prevState,
        [e.target.name]: isEmpty,
      }))
      : setIsAnyInputEmpty((prevState) => ({
        ...prevState,
        [e.target.name]: isEmpty,
      }));
  };
  const [form, setForm] = useState(initialFormState);
  const [formCourse, setFormCourse] = useState(initialFormCourseState);
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let toastId = null; // Declare toastId

    const updatedIsAnyInputEmpty = Object.keys(form).reduce((acc, key) => {
      acc[key] = form[key] === "";
      console.log(key);
      return acc;
    }, {});
    console.log(updatedIsAnyInputEmpty);
    setIsAnyInputEmpty(updatedIsAnyInputEmpty);
    const isAnyInputEmpty = Object.values(updatedIsAnyInputEmpty).some(
      (value) => value === true
    );
    if (!isAnyInputEmpty) {
      try {
        toastId = LoadingToast("Wait a few seconds");
        const response = await postContactUs(form);
        console.log(response);
        toast.dismiss(toastId);
        setResponseMessage(
          lng === "Arabic"
            ? response.message_ar
            : lng === "Deutsch"
              ? response.message_gr
              : response.message_en
        );
        setForm(initialFormState);
        setSuccessPopupOpen(true);
      } catch (error) {
        console.error("Post contact us information error:", error);
        toast.dismiss(toastId);
        if (error.response && error.response.data.errors) {
          toast(error.response.data.errors[0]);
        }
      }
    }
  };

  const handleCourseSubmit = async (e) => {
    e.preventDefault();
    let toastId = null; // Declare toastId
    const updatedIsAnyInputsCourseEmpty = Object.keys(formCourse).reduce(
      (acc, key) => {
        acc[key] = formCourse[key] === "";
        return acc;
      },
      {}
    );
    setIsAnyInputsCourseEmpty(updatedIsAnyInputsCourseEmpty);

    const isAnyInputsCourseEmpty = Object.values(
      updatedIsAnyInputsCourseEmpty
    ).some((value) => value === true);
    if (!isAnyInputsCourseEmpty) {
      try {
        const formData = new FormData();
        formData.append("first_name", formCourse.child_name);
        formData.append("last_name", formCourse.family_name);
        formData.append("father_name", formCourse.father_name);
        formData.append("mother_name", formCourse.mother_name);
        formData.append("age", formCourse.child_age);
        formData.append("phone", formCourse.father_phone);
        formData.append("email", formCourse.father_email);
        formData.append("ids[0][language_id]", 1);
        formData.append("ids[0][level]", formCourse.arabic_level);
        formData.append("ids[1][language_id]", 2);
        formData.append("ids[1][level]", formCourse.english_level);
        formData.append("ids[2][language_id]", 3);
        formData.append("ids[2][level]", formCourse.german_level);
        formData.append("ids[3][language_id]", 4);
        formData.append("ids[3][level]", formCourse.Kurdish_level);
        formData.append("course_id", courseId);

        toastId = LoadingToast("Wait a few seconds");
        const response = await registerInCourse(formData);
        console.log(response);
        console.log(response.student.first_name);
        console.log(response.student.id);
        setStudentNumber(response.student.number);
        toast.dismiss(toastId);
        setResponseMessage(
          lng === "Arabic"
            ? response.message_ar
            : lng === "Deutsch"
              ? response.message_gr
              : response.message_en
        );
        setSuccessPopupOpen(true);
        setForm(initialFormCourseState);
      } catch (error) {
        console.error("Post contact us information error:", error);
        toast.dismiss(toastId);
        if (error.response && error.response.data.errors) {
          toast(error.response.data.errors[0]);
        }
      }
    }
  };
  return (
    <div
      id="contact_us"
      className={`${courseRegister} ${theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
        } py-[60px] max-md:p-[10px]   max-sm:-mb-[30px] max-sm:mt-[30px]`}
    >
      <div>
        <div className={`${courseRegister && "hidden"} md:mb-[30px]`}>
          <div className="flex flex-col font-semibold">
            <h1
              className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                } text-transparent bg-clip-text`}
            >
              {t("TALK TO US")}
            </h1>
            <h2 className="leading-[52px] text-[48px] max-xl:text-[40px] max-md:text-[22px] max-md:leading-[32px]">
              {t("Join us in forging your digital future")}
            </h2>
            <h3 className="my-2 text-text18 max-md:text-text16">
              {t(
                "Whether you’re ready to begin or have inquiries, reach out to discuss how we can assist you."
              )}
            </h3>
          </div>
        </div>
        <div className="flex max-lg:flex-col-reverse max-lg:mt-4 max-lg:gap-10 2xl:gap-[200px]">
          <div className="basis-[50%] lg:h-[400px] lg:my-auto ">
            <img className="w-full h-full" src={map} alt="" />
          </div>
          {courseRegister ? (
            <form
              onSubmit={handleCourseSubmit}
              className={`${theme === "dark"
                ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
                } basis-[50%] rounded-xl flex flex-col gap-7 max-md:gap-4 p-5 border`}
            >
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputsCourseEmpty.child_name
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputsCourseEmpty.child_name
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("Child Name*")}
                name="child_name"
                value={formCourse.child_name}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputsCourseEmpty.family_name
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputsCourseEmpty.family_name
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg`}
                type="text"
                placeholder={t("Family Name")}
                name="family_name"
                value={formCourse.family_name}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputsCourseEmpty.father_name
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputsCourseEmpty.father_name
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("Father Name*")}
                name="father_name"
                value={formCourse.father_name}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputsCourseEmpty.mother_name
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputsCourseEmpty.mother_name
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg`}
                type="text"
                placeholder={t("Mother Name*")}
                name="mother_name"
                value={formCourse.mother_name}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputsCourseEmpty.child_age
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputsCourseEmpty.child_age
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="number"
                placeholder={t("Child Age*")}
                maxLength={2}
                name="child_age"
                value={formCourse.child_age}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputsCourseEmpty.father_email
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputsCourseEmpty.father_email
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("Parent's Email*")}
                name="father_email"
                value={formCourse.father_email}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputsCourseEmpty.father_phone
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputsCourseEmpty.father_phone
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="number"
                placeholder={t("Parent's Phone*")}
                name="father_phone"
                value={formCourse.father_phone}
                pattern="[0-9]*"
                inputMode="numeric"
              />
              <select
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 text-[#a2a2a2] ${isAnyInputsCourseEmpty.arabic_level
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 text-[#777] ${isAnyInputsCourseEmpty.arabic_level
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("Arabic level*")}
                name="arabic_level"
                value={formCourse.arabic_level}
              >
                <option defaultChecked disabled value="">
                  {t("Choose Arabic Level (Conversation)*")}
                </option>
                <option value={t("Levelless")}>{t("Levelless")}</option>
                <option value={t("Weak")}>{t("Weak")}</option>
                <option value={t("Middle")}>{t("Middle")}</option>
                <option value={t("Good")}>{t("Good")}</option>
                <option value={t("Perfect")}>{t("Perfect")}</option>
              </select>
              <select
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 text-[#a2a2a2] ${isAnyInputsCourseEmpty.english_level
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 text-[#777] ${isAnyInputsCourseEmpty.english_level
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("English Level*")}
                name="english_level"
                value={formCourse.english_level}
              >
                <option defaultChecked disabled value="">
                  {t("Choose English Level (Conversation)*")}
                </option>
                <option value={t("Levelless")}>{t("Levelless")}</option>
                <option value={t("Weak")}>{t("Weak")}</option>
                <option value={t("Middle")}>{t("Middle")}</option>
                <option value={t("Good")}>{t("Good")}</option>
                <option value={t("Perfect")}>{t("Perfect")}</option>
              </select>
              <select
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 text-[#a2a2a2] ${isAnyInputsCourseEmpty.german_level
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 text-[#777] ${isAnyInputsCourseEmpty.german_level
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("German Level*")}
                name="german_level"
                value={formCourse.german_level}
              >
                <option defaultChecked disabled value="">
                  {t("Choose German Level (Conversation)*")}
                </option>
                <option value={t("Levelless")}>{t("Levelless")}</option>
                <option value={t("Weak")}>{t("Weak")}</option>
                <option value={t("Middle")}>{t("Middle")}</option>
                <option value={t("Good")}>{t("Good")}</option>
                <option value={t("Perfect")}>{t("Perfect")}</option>
              </select>
              <select
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 text-[#a2a2a2] ${isAnyInputsCourseEmpty.Kurdish_level
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 text-[#777] ${isAnyInputsCourseEmpty.Kurdish_level
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("Kurdish Level*")}
                name="Kurdish_level"
                value={formCourse.Kurdish_level}
              >
                <option defaultChecked disabled value="">
                  {t("Choose Kurdish Level (Conversation)*")}
                </option>
                <option value={t("Levelless")}>{t("Levelless")}</option>
                <option value={t("Weak")}>{t("Weak")}</option>
                <option value={t("Middle")}>{t("Middle")}</option>
                <option value={t("Good")}>{t("Good")}</option>
                <option value={t("Perfect")}>{t("Perfect")}</option>
              </select>

              <MainButton
                type="submit"
                className="w-[100px]"
                content={t("Send")}
              />
            </form>
          ) : (
            <form
              onSubmit={handleSubmit}
              className={`${theme === "dark"
                ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
                } basis-[50%] rounded-xl flex flex-col gap-7 max-md:gap-4 p-5 border`}
            >
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 focus:bg-black/25 ${isAnyInputEmpty.first_name
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputEmpty.first_name
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("First Name*")}
                name="first_name"
                value={form.first_name}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 ${isAnyInputEmpty.last_name
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputEmpty.last_name
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg `}
                type="text"
                placeholder={t("Last Name*")}
                name="last_name"
                value={form.last_name}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 ${isAnyInputEmpty.phone
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputEmpty.phone
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg`}
                type="text"
                placeholder={t("Phone Number*")}
                name="phone"
                value={form.phone}
                pattern="[0-9]*"
                inputMode="numeric"
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 ${isAnyInputEmpty.email
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputEmpty.email
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg`}
                type="text"
                placeholder={t("Email*")}
                name="email"
                value={form.email}
              />
              <input
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 ${isAnyInputEmpty.compony
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputEmpty.compony
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg`}
                type="text"
                placeholder={t("Company Name*")}
                name="compony"
                value={form.compony}
              />
              <textarea
                onChange={handleChange}
                className={`${theme === "dark"
                  ? `hover:border-white focus:border-white bg-black/25 ${isAnyInputEmpty.subject
                    ? "border-red-600"
                    : "border-white/25"
                  }`
                  : `hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777] ${isAnyInputEmpty.subject
                    ? "border-red-400"
                    : "border-black/25"
                  }`
                  } px-3 py-2 min-h-[100px] max-h-[100px] border focus:ring-0 duration-300 outline-none cursor-pointer rounded-lg resize-none`}
                placeholder={t("Message*")}
                name="subject"
                value={form.subject}
              ></textarea>
              <MainButton
                type="submit"
                className="w-[100px]"
                content={t("Send")}
              />
            </form>
          )}
        </div>
        <Popup
          toggleSwitch={successPopupOpen}
          children={
            <div
              className={`${theme === "dark"
                ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
                } flex flex-col text-center gap-5 p-7`}
            >
              <div
                className={`bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                  } text-transparent bg-clip-text flex flex-col text-center gap-2 text-[24px] max-xl:text-[22px] max-md:text-[16px] font-bold `}
              >
                <div>
                  {courseRegister ? responseMessage : t(responseMessage)}
                </div>
              </div>
              <div className="flex justify-center items-center max-md:flex-col gap-2">
                {courseRegister && (
                  <MainButton
                    className="w-fit px-4"
                    onClick={() => {
                      setSuccessPopupOpen(false);
                    }}
                    content={t("Close")}
                  />
                )}
                <MainButton
                  link={courseRegister ? `/survey/${studentNumber}` : null}
                  className="w-fit px-4"
                  onClick={() => {
                    setSuccessPopupOpen(false);
                    setResponseMessage(""); // Reset the message when closing the popup
                  }}
                  content={courseRegister ? t("Next") : t("Ok")}
                />
              </div>
            </div>
          }
          zIndex="z-40 animate-flashing"
          className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] overflow-hidden text-MainText duration-300 max-w-[769px] max-md:w-full max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 animate-flashing`}
        />
      </div>
    </div>
  );
}

export default ContactUs;
