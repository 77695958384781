import React from "react";
import Skeleton from "react-loading-skeleton";

function SurveySkeleton() {
  return (
    <div className="grid grid-cols-2 max-md:grid-cols-1 gap-2">
      <div className="w-[50%] max-md:w-[75%] h-[30px]">
        <Skeleton width={`100%`} height={`100%`} />
      </div>
      {Array.from({ length: 5 }).map((_, inIndex) => (
        <div className="w-[30%] max-md:w-[60%] h-[25px]" key={inIndex}>
          <Skeleton width={`100%`} height={`100%`} />
        </div>
      ))}
    </div>
  );
}

export default SurveySkeleton;
