import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTheme } from "../../Context/ThemeContext";

function LoadingPage() {
  const { theme } = useTheme();
  return (
    <div
      className={`${
        theme === "dark"
          ? "bg-MainBgColorDM text-MainTextColorDM"
          : "bg-MainBgColor text-MainTextColor"
      } h-screen w-full flex justify-center items-center`}
    >
      <AiOutlineLoading3Quarters className="animate-spin text-[40px]" />
    </div>
  );
}

export default LoadingPage;
