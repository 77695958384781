import React, { useContext, useEffect, useState } from "react";
import ContactUs from "./Home/Sections/ContactUs";
import Container from "../Components/Container";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { getCourseDetails, getUserInfo } from "../../APIs/api";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import PrivacySettings from "../Components/PrivacySettings";
import { PopupContext } from "../../Context/PopupContext";
import CourseDetailsSkeleton from "../Components/SkeletonLoading/CourseDetailsSkeleton";
import ScrollTopButton from "../Components/ScrollTopButton";

function CourseDetails() {
  const lng = localStorage.getItem("language") || "English";
  const { t } = useTranslation();
  const { theme } = useTheme();
  const { setPrivacySettingsOpen } = useContext(PopupContext);
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState({ name: "", courseDetails: "" });
  const fetchCourseDetails = async () => {
    try {
      setLoading(true);
      const response = await getCourseDetails(1);
      console.log(response.data);
      setCourse({
        name:
          lng === "English"
            ? response.data.name_en
            : lng === "Deutsch"
              ? response.data.name_gr
              : response.data.name_ar,
        courseDetails:
          lng === "English"
            ? response.data.course_details_en
            : lng === "Deutsch"
              ? response.data.course_details_gr
              : response.data.course_details_ar,
      });
    } catch (err) {
      console.error("fetch course details err : ", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserInfo();
    setPrivacySettingsOpen(true);
  }, []);
  useEffect(() => {
    fetchCourseDetails();
  }, [lng]);
  return (
    <div>
      <Container>
        <NavBar />
        {loading ? (
          <CourseDetailsSkeleton />
        ) : (
          <div className="flex flex-col gap-5 max-md:-mb-5 max-md:p-[10px]">
            <div>
              <div className="flex flex-col font-semibold">
                <h1
                  className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                    } text-transparent bg-clip-text`}
                >
                  {t("Course Details")}
                </h1>
                <h2 className="leading-[52px] text-[48px] max-xl:text-[40px] max-md:text-[22px] max-md:leading-[32px]">
                  {t("Here you will find all the course details")}
                </h2>
              </div>
            </div>
            <div className="text-text18 max-md:text-text16">
              <span
                className={`bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                  } text-transparent bg-clip-text font-semibold`}
              >
                {t("Course Title")} :{" "}
              </span>
              <span>{course?.name}</span>
            </div>
            <div className="text-text18 max-md:text-text16">
              <span
                className={`bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                  } text-transparent bg-clip-text font-semibold`}
              >
                {t("Course description")} :{" "}
              </span>
              <span>{course.courseDetails?.description}</span>
            </div>
            <table
              className={`${theme === "dark"
                ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
                } rounded-xl border text-text18 max-md:text-text16 max-md:hidden`}
            >
              <thead>
                <tr>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Age")}
                  </th>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Max students in class")}
                  </th>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Course duration")}
                  </th>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Session duration")}
                  </th>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Session time")}
                  </th>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Session dates")}
                  </th>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Registration limit")}
                  </th>
                  <th
                    className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1`}
                  >
                    {t("Cost")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.age_group}
                  </td>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.max_students_per_class}
                  </td>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.course_duration}
                  </td>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.session_duration}
                  </td>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.session_time}
                  </td>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.session_dates}
                  </td>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.registration_limit}
                  </td>
                  <td
                    className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                      } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                  >
                    {course.courseDetails?.details?.cost}
                  </td>
                </tr>
              </tbody>
            </table>
            <table
              className={`${theme === "dark"
                ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
                } rounded-xl border text-text18 max-md:text-text16 md:hidden`}
            >
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Age")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.age_group}
                </td>
              </tr>
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Max students in class")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.max_students_per_class}
                </td>
              </tr>
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Course duration")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.course_duration}
                </td>
              </tr>
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Session duration")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.session_duration}
                </td>
              </tr>
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Session time")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.session_time}
                </td>
              </tr>
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Session dates")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.session_dates}
                </td>
              </tr>
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Registration limit")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.registration_limit}
                </td>
              </tr>
              <tr>
                <th
                  className={`${theme === "dark" ? "border-gray-600" : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1`}
                >
                  {t("Cost")}
                </th>
                <td
                  className={`${theme === "dark" ? "border-gray-600 " : "border-gray-800"
                    } border px-3 py-2 max-md:px-2 max-md:py-1 text-center`}
                >
                  {course.courseDetails?.details?.cost}
                </td>
              </tr>
            </table>
            <div className="text-text18 max-md:text-text16">
              <span
                className={`bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                  } text-transparent bg-clip-text font-semibold`}
              >
                {t("What will we learn?")} :{" "}
              </span>
            </div>
            <table
              className={`${theme === "dark"
                ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
                } rounded-xl border text-text18 max-md:text-text16 `}
            >
              <tbody>
                {course.courseDetails?.learning_outcomes?.map(
                  (outcome, index) => (
                    <tr key={index}>
                      <th
                        className={`${theme === "dark"
                          ? "border-gray-600 "
                          : "border-gray-800"
                          } border px-3 py-2 max-md:px-2 max-md:py-1`}
                      >
                        {outcome.title}
                      </th>
                      <td
                        className={`${theme === "dark"
                          ? "border-gray-600 "
                          : "border-gray-800"
                          } border px-3 py-2 max-md:px-2 max-md:py-1`}
                      >
                        {outcome.description}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
            <div className="text-text18 max-md:text-text16">
              <span
                className={`bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                  } text-transparent bg-clip-text font-semibold`}
              >
                {t("So")} :{" "}
              </span>
              <span>{course.courseDetails?.note}</span>
            </div>
            <div className="text-text18 max-md:text-text16">
              <span
                className={`bg-gradient-to-r from-SecondTextColor ${theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                  } text-transparent bg-clip-text font-semibold`}
              >
                {t("Note on registration")} :{" "}
              </span>
              <span>{course.courseDetails?.registration?.note}</span>
            </div>
          </div>
        )}
        <ContactUs courseRegister={true} courseId={1} />
        <Footer />
        <PrivacySettings />
        <ScrollTopButton />
      </Container>
    </div>
  );
}

export default CourseDetails;
