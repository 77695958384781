import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { PopupContext } from "../../Context/PopupContext";
import MainButton from "./MainButton";
import Popup from "./Popup";
import { MdLanguage } from "react-icons/md";

function PrivacySettings() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { privacySettingsOpen, setPrivacySettingsOpen, setLanguageOpen } =
    useContext(PopupContext);
  return (
    <Popup
      toggleSwitch={privacySettingsOpen}
      children={
        <div
          className={`${
            theme === "dark"
              ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
              : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
          } w-full p-5 flex flex-col max-md:h-fit`}
        >
          <div
            className={`${
              theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
            } pb-[12px]`}
          >
            <div
              className={`flex justify-between items-center mb-[8px] font-[700] text-[18px] leading-[14px]`}
            >
              <h1 className={`text-[19px] font-[700] leading-[18px]`}>
                {t("Data Protection Options")}
              </h1>
              <MdLanguage
                onClick={() => {
                  setLanguageOpen(true);
                }}
                size={20}
                className="cursor-pointer"
              />
            </div>
            <div className={`py-[4px] leading-[1.25] bm-[8px]`}>
              {t(
                "Our website employs third-party tracking technologies from other websites to enhance and personalize our services, as well as for advertising objectives. The installation of optional cookies is contingent upon your approval, which you can withdraw at any moment."
              )}
            </div>
          </div>
          <MainButton
            content={`${t("Agree")}`}
            className="max-w-[150px]"
            onClick={() => setPrivacySettingsOpen(false)}
          />
        </div>
      }
      zIndex="z-20 animate-flashing"
      className={`${
        privacySettingsOpen ? "opacity-100 z-30" : "opacity-0"
      } md:bottom-5 bottom-0 left-[50%] -translate-x-[50%] fixed overflow-hidden max-md:rounded-b-none rounded-[20px] text-MainText languageBoxShadow duration-300 max-w-[800px] max-md:w-[98%] animate-flashing`}
      onClick={() => setPrivacySettingsOpen(false)}
    />
  );
}

export default PrivacySettings;
