import React from "react";
import MainButton from "../../../Components/MainButton";
import { useTheme } from "../../../../Context/ThemeContext";
import { useTranslation } from "react-i18next";

function Landing() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  return (
    <div
      className={`${
        theme === "dark"
          ? "text-MainTextColorDM bg-LandingDM"
          : "text-MainTextColor bg-LandingLM"
      } relative flex h-[520px] bg-cover`}
    >
      <div className="mb-auto mt-auto max-md:my-auto max-md:px-[10px] basis-[80%] max-md:basis-[100%]">
        <h1 className="font-semibold leading-[66px] mb-5 text-[64px] max-xl:text-[56px] max-lg:text-[44px] max-md:text-[34px] max-md:leading-[36px]">
          {t("We Bring Your Digital Dreams to Life.")}
        </h1>
        <h2 className="text-[20px] max-md:text-text18 font-medium mb-[25px] max-md:max-w-[400px] max-sm:mb-5">
          {t(
            `After TECREK revamped our app, our sales conversion rates soared." - TECREK Customer`
          )}
        </h2>
        <MainButton
          className="w-fit"
          content={t("Talk to us")}
          onClick={() =>
            window.scrollTo({
              top: document.getElementById("contact_us").offsetTop,
              behavior: "smooth",
            })
          }
        />
      </div>
    </div>
  );
}

export default Landing;
