import React, { useEffect, useState } from "react";
import left_circles from "../../assets/OurWork/left_circles.png";
import right_circles from "../../assets/OurWork/right_circles.png";
import { getProjects } from "../../APIs/api";
import Product from "../Components/Product";
import { useTheme } from "../../Context/ThemeContext";
import Container from "../Components/Container";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import ProductSkeleton from "../Components/SkeletonLoading/ProductSkeleton";
import ScrollTopButton from "../Components/ScrollTopButton";

function OurWork() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const lng = localStorage.getItem("language") || "English";

  useEffect(() => {
    const fetchProjectsData = async () => {
      try {
        const projectsData = await getProjects();
        console.log(projectsData);
        setProjects(projectsData);
        setLoading(false);
      } catch (error) {
        console.log("fetch projects error", error);
      }
    };
    fetchProjectsData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Container>
        <NavBar />
        <div
          className={`${
            theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
          } pt-[20px] pb-[60px] max-md:p-[10px] max-sm:-mb-[30px]`}
        >
          <div className="md:mb-[30px]">
            <div className="flex flex-col font-semibold">
              <h1
                className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${
                  theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                } text-transparent bg-clip-text`}
              >
                {t("OUR WORK")}
              </h1>
              <h2 className="leading-[52px] text-[48px] max-xl:text-[40px] max-md:text-[22px] max-md:leading-[32px]">
                {t(
                  "We collaborate closely with our clients to design solutions"
                )}
              </h2>
              {loading ? (
                <div className="flex flex-col gap-5 ">
                  <ProductSkeleton />
                  <ProductSkeleton className="flex-row-reverse" />
                  <ProductSkeleton />
                  <ProductSkeleton className="flex-row-reverse" />
                </div>
              ) : (
                <div className="flex flex-col gap-10 mt-[30px]">
                  {projects &&
                    projects.map((project, index) => (
                      <Product
                        key={index}
                        className={
                          index % 2 === 0 ? "flex" : "flex-row-reverse"
                        }
                        image={project.logo}
                        circles={index % 2 === 0 ? left_circles : right_circles}
                        circlesClassName={
                          index % 2 === 0
                            ? "left-0 top-0 w-[200px] max-lg:w-[150px] max-md:hidden opacity-60"
                            : "right-0 top-0 w-[300px] max-lg:w-[200px] max-md:hidden opacity-60"
                        }
                        id={project.id}
                        name={project.name}
                        type={
                          lng === "English"
                            ? project.type
                            : lng === "Deutsch"
                            ? project.type_gr
                            : project.type_ar
                        }
                        mainDescription={
                          lng === "English"
                            ? project.main_description
                            : lng === "Deutsch"
                            ? project.main_description_gr
                            : project.main_description_ar
                        }
                      />
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </Container>
      <ScrollTopButton />
    </div>
  );
}

export default OurWork;
