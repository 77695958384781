import React, { useState, useRef, useEffect } from "react";
import NavBar from "../Components/NavBar";
import Container from "../Components/Container";
import Footer from "../Components/Footer";
import ScrollTopButton from "../Components/ScrollTopButton";
import MainButton from "../Components/MainButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { getTestQuetions, postTestAnswers } from "../../APIs/api";
import { toast } from "react-toastify";
import { LoadingToast } from "../Components/ToastifyNotification/Toast";
import { useNavigate, useParams } from "react-router";
import SurveySkeleton from "../Components/SkeletonLoading/SurveySkeleton";
import Popup from "../Components/Popup";

function ComputerSurveyByStudentNumber() {
  const navigate = useNavigate();
  const { studentNumber } = useParams();
  const lng = localStorage.getItem("language");
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [emptyQuestions, setEmptyQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [student, setStudent] = useState({
    id: "",
    firstName: "",
    lastName: "",
  });
  const [successPopupOpen, setSuccessPopupOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const questionRefs = useRef([]);

  const fetchSurveyData = async () => {
    try {
      setLoading(true);
      const response = await getTestQuetions(studentNumber);
      setStudent({
        id: response.data.student.id,
        firstName: response.data.student.first_name,
        lastName: response.data.student.last_name,
      });

      // No need to add "Others" option, just set the questions directly
      setQuestions(response.data.questions);
    } catch (err) {
      console.error("fetch test data err: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerChange = (index, answer) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: answer,
    }));

    // Remove from emptyQuestions if it was empty before
    setEmptyQuestions((prev) => prev.filter((i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newEmptyQuestions = questions
      .map((question, index) => {
        const selectedOption = selectedAnswers[index];

        if (!selectedOption) {
          return index;
        } else {
          return null;
        }
      })
      .filter((index) => index !== null);

    if (newEmptyQuestions.length > 0) {
      setEmptyQuestions(newEmptyQuestions);
      questionRefs.current[newEmptyQuestions[0]].scrollIntoView({
        behavior: "smooth",
      });
    } else {
      try {
        const formData = new FormData();

        questions.forEach((question, index) => {
          formData.append(`ids[${index}][question_id]`, question.id);
          const selectedOption = question.options.find(
            (opt) =>
              opt[`text_${lng}`] === selectedAnswers[index] ||
              opt.text_en === selectedAnswers[index]
          );

          if (selectedOption) {
            formData.append(`ids[${index}][option_id]`, selectedOption.id);
          }
        });

        const toastId = LoadingToast("Wait a few seconds");
        const response = await postTestAnswers(student.id, formData);
        console.log(response);
        toast.dismiss(toastId);
        if (response.success === "true") {
          setResponseMessage(
            lng === "Arabic"
              ? response.data.message_ar
              : lng === "Deutsch"
              ? response.data.message_gr
              : response.data.message_en
          );
          setSuccessPopupOpen(true);
        }
      } catch (err) {
        console.error("post test answers err : ", err);
      }
    }
  };

  useEffect(() => {
    fetchSurveyData();
  }, [lng]);

  return (
    <div>
      <Container>
        <NavBar />
        <div className="flex flex-col gap-5 max-md:p-[10px] md:mb-[50px]">
          <div>
            {t(
              "We invite all parents to participate in answering 25 questions designed to assess your children’s understanding of computer basics. This test will help us understand your child’s technological capabilities and identify the support needed to enhance their skills. We appreciate your cooperation and assure you of the confidentiality and security of the information provided."
            )}
          </div>
          <div>
            {t("Thank you for your participation and ongoing support.")}
          </div>
          <div>
            <span
              className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${
                theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
              } text-transparent bg-clip-text`}
            >
              {t("Child Name")} :{" "}
            </span>{" "}
            {`${student.firstName} ${student.lastName}`}
          </div>
          <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-5">
              {loading
                ? Array.from({ length: 25 }).map((_, index) => (
                    <div key={index}>
                      <SurveySkeleton />
                    </div>
                  ))
                : questions.map((question, index) => (
                    <div
                      key={question.id}
                      ref={(el) => (questionRefs.current[index] = el)}
                      className={`rounded-[10px] ${
                        emptyQuestions.includes(index)
                          ? "border border-red-500"
                          : ""
                      } flex flex-col gap-2`}
                    >
                      <h3
                        className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${
                          theme === "dark"
                            ? "to-MainTextColorDM"
                            : "to-MainTextColor"
                        } text-transparent bg-clip-text max-md:text-text16`}
                      >
                        {question.id}
                        {" - "}
                        {lng === "Arabic"
                          ? question.text_ar
                          : lng === "Deutsch"
                          ? question.text_gr
                          : question.text_en}
                      </h3>
                      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-2">
                        {question.options.map((option) => (
                          <label
                            key={option.id}
                            className="cursor-pointer flex items-center gap-1"
                          >
                            <input
                              type="radio"
                              name={`question-${index}`}
                              value={option[`text_${lng}`] || option.text_en}
                              onChange={() =>
                                handleAnswerChange(
                                  index,
                                  option[`text_${lng}`] || option.text_en
                                )
                              }
                            />
                            <span>
                              {lng === "Arabic"
                                ? option.text_ar
                                : lng === "Deutsch"
                                ? option.text_gr
                                : option.text_en}
                            </span>
                          </label>
                        ))}
                      </div>
                      {emptyQuestions.includes(index) && (
                        <p className={`text-red-500 mt-1`}>
                          {t("This question is required")}
                        </p>
                      )}
                    </div>
                  ))}
            </div>
            <MainButton
              content={t("Send")}
              type="submit"
              className="w-[100px]"
            />
          </form>
        </div>
        <Popup
          toggleSwitch={successPopupOpen}
          children={
            <div
              className={`${
                theme === "dark"
                  ? "border-gray-600 bg-gradient-to-r from-MainBgColorDM via-[#1c1c1d] to-MainBgColorDM shadow-ContactUsFormBoxShadowDM"
                  : "border-gray-800 bg-gradient-to-r from-MainBgColor via-[#dadae4] to-MainBgColor shadow-ContactUsFormBoxShadowLM"
              } flex flex-col text-center gap-5 p-7`}
            >
              <div
                className={`bg-gradient-to-r from-SecondTextColor ${
                  theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                } text-transparent bg-clip-text flex flex-col text-center gap-2 text-[24px] max-xl:text-[22px] max-md:text-[20px] font-bold `}
              >
                <div>{responseMessage}</div>
              </div>
              <MainButton
                link={`/courseDetails/1`}
                className="mx-auto px-4"
                onClick={() => {
                  setSuccessPopupOpen(false);
                  setResponseMessage("");
                }}
                content={t("Ok")}
              />
            </div>
          }
          zIndex="z-40 animate-flashing"
          className={`z-50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] overflow-hidden text-MainText duration-300 max-w-[769px] max-md:w-full max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 animate-flashing`}
        />
        <Footer />
        <ScrollTopButton />
      </Container>
    </div>
  );
}

export default ComputerSurveyByStudentNumber;
