import React from "react";
import web_app from "../../../../assets/WhatWeDo/web_app.png";
import mobile_app from "../../../../assets/WhatWeDo/mobile_app.png";
import marketing from "../../../../assets/WhatWeDo/marketing.png";
import social from "../../../../assets/WhatWeDo/social.png";
import e_commerce from "../../../../assets/WhatWeDo/e_commerce.png";

import api_development from "../../../../assets/WhatWeDo/api_development.png";
import maintenance from "../../../../assets/WhatWeDo/maintenance.png";
import web_design from "../../../../assets/WhatWeDo/web_design.png";
import WhatWeDoItem from "../../../Components/WhatWeDoItem";
import { useTheme } from "../../../../Context/ThemeContext";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

function WhatWeDo() {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <div
      className={`${
        theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
      } pt-[60px] max-md:p-[10px] font-semibold relative max-md:mt-[30px]`}
    >
      <h2
        className={`text-text18 leading-[52px] w-fit bg-gradient-to-r from-SecondTextColor ${
          theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
        } text-transparent bg-clip-text`}
      >
        {t("WHAT WE DO")}
      </h2>
      <div className="grid grid-cols-3 max-xl:grid-cols-2 max-md:grid-cols-1 gap-[30px] max-md:hidden">
        <WhatWeDoItem
          image={web_app}
          title={t("Web Applications")}
          description={t(
            "Tailored multi-functional web platforms, designed specifically for your business needs."
          )}
        />
        <WhatWeDoItem
          image={mobile_app}
          title={t("Mobile Apps")}
          description={t(
            "Develop mobile applications efficiently and affordably. Available for both Android and iOS platforms."
          )}
        />
        <WhatWeDoItem
          image={marketing}
          title={t("Digital Marketing")}
          description={t(
            "Accelerate your success with our dynamic digital marketing solutions, tailored to maximize your online impact."
          )}
        />
        <WhatWeDoItem
          image={social}
          title={t("Managed Social Media")}
          description={t(
            "Elevate your brand with our strategic social media management services, designed to boost engagement and visibility."
          )}
        />
        <WhatWeDoItem
          image={e_commerce}
          title={t("E-Commerce")}
          description={t(
            "Enhance your online sales with our versatile ready-made and custom e-commerce solutions."
          )}
        />
        <WhatWeDoItem
          image={api_development}
          title={t("API Development")}
          description={t(
            "Create your own REST API with comprehensive documentation to support third-party integrations."
          )}
        />
        <WhatWeDoItem
          image={maintenance}
          title={t("Maintenance")}
          description={t(
            "Enjoy a hassle-free experience as we handle your web and app maintenance, allowing you to concentrate on your core business."
          )}
        />
        <WhatWeDoItem
          image={web_design}
          title={t("Web Design")}
          description={t(
            "Modernize your website with cutting-edge designs and the latest UX/UI principles to enhance user engagement."
          )}
        />
      </div>
      <div style={{ direction: "ltr" }} className="md:hidden whatWeDoSwiper">
        <Swiper
          modules={[Pagination, Navigation, Autoplay]}
          spaceBetween={10}
          pagination={{ clickable: true }}
          autoplay
          navigation
          breakpoints={{
            1280: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1,
            },
          }}
        >
          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={web_app}
              title={t("Web Applications")}
              description={t(
                "Tailored multi-functional web platforms, designed specifically for your business needs."
              )}
            />
          </SwiperSlide>
          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={mobile_app}
              title={t("Mobile Apps")}
              description={t(
                "Develop mobile applications efficiently and affordably. Available for both Android and iOS platforms."
              )}
            />
          </SwiperSlide>
          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={marketing}
              title={t("Digital Marketing")}
              description={t(
                "Accelerate your success with our dynamic digital marketing solutions, tailored to maximize your online impact."
              )}
            />
          </SwiperSlide>
          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={social}
              title={t("Managed Social Media")}
              description={t(
                "Elevate your brand with our strategic social media management services, designed to boost engagement and visibility."
              )}
            />
          </SwiperSlide>

          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={e_commerce}
              title={t("E-Commerce")}
              description={t(
                "Enhance your online sales with our versatile ready-made and custom e-commerce solutions."
              )}
            />
          </SwiperSlide>
          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={api_development}
              title={t("API Development")}
              description={t(
                "Create your own REST API with comprehensive documentation to support third-party integrations."
              )}
            />
          </SwiperSlide>
          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={maintenance}
              title={t("Maintenance")}
              description={t(
                "Enjoy a hassle-free experience as we handle your web and app maintenance, allowing you to concentrate on your core business."
              )}
            />
          </SwiperSlide>
          <SwiperSlide className="pb-[30px]">
            <WhatWeDoItem
              image={web_design}
              title={t("Web Design")}
              description={t(
                "Modernize your website with cutting-edge designs and the latest UX/UI principles to enhance user engagement."
              )}
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default WhatWeDo;
