import React from "react";
import { useTheme } from "../../Context/ThemeContext";

function ReviewItem({ id, logo, image, name, company, note }) {
  const { theme } = useTheme();
  const lng = localStorage.getItem("language") || "English";
  return (
    <div
      dir={lng === "Arabic" ? "rtl" : "ltr"}
      key={id}
      className={`${
        theme === "dark" ? "text-MainTextColorDM" : "text-MainTextColor"
      } duration-300 py-8 rounded-[20px] flex flex-col gap-2 max-md:mb-[20px] max-w-[600px]`}
    >
      <div className="w-[100px] h-[100px] overflow-hidden">
        <img className="w-full h-full object-contain" src={logo} alt="" />
      </div>
      <div
        className={`text-[18px] max-md:text-text16 font-medium py-2 min-h-[200px] max-h-[200px] overflow-hidden scrollbar-thumb-rounded-full scrollbar-track-rounded-full hover:scrollbar scrollbar-w-[6px] ${
          theme === "dark"
            ? "scrollbar-thumb-MainTextColorDM"
            : "scrollbar-thumb-MainTextColor"
        }  hover:overflow-y-scroll`}
      >
        {note}
      </div>
      <div className="flex items-center gap-4">
        <div className="w-[100px] h-[100px] rounded-full overflow-hidden">
          <img className="w-full h-full object-cover" src={image} alt="" />
        </div>
        <div className="flex items-center max-md:justify-center gap-2 text-[28px] max-md:text-[24px] font-semibold">
          <h1>{name}</h1>
        </div>
      </div>
    </div>
  );
}

export default ReviewItem;
