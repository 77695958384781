import React, { useContext } from "react";
import logoLM from "../../assets/Logo/logoLM.png";
import logoDM from "../../assets/Logo/logoDM.png";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagramSquare,
  FaLinkedin,
  FaPhone,
} from "react-icons/fa";
import { IoIosArrowDown, IoLogoWhatsapp } from "react-icons/io";

import { FaMessage } from "react-icons/fa6";
import { useTheme } from "../../Context/ThemeContext";
import LanguageButton from "./LanguageButton";
import SelectLanguagePopup from "../Components/SelectLanguagePopup";
import { useTranslation } from "react-i18next";
import { PopupContext } from "../../Context/PopupContext";
import ChooseContactUsWay from "./ChooseContactUsWay";

function Footer({ showLink }) {
  const { theme, setTheme } = useTheme();
  const { t } = useTranslation();
  const { setChooseContactUsWayOpen } = useContext(PopupContext);
  const isDarkMode = theme === "dark";

  return (
    <div className="mt-5 pb-7 grid grid-cols-3 max-md:grid-cols-1 max-md:gap-y-5 max-sm:mt-[50px] max-sm:px-3">
      <div className="flex flex-col items-start gap-5 mr-4 min-w-[190px] ">
        <Link to="/">
          <div className="w-[150px] h-[70px] shadow-LogoShadow">
            <img
              className="w-full h-full object-contain"
              src={theme === "dark" ? logoDM : logoLM}
              alt="My Logo"
            />
          </div>
        </Link>
        <div className="flex justify-center gap-4 items-center max-lg:ml-7">
          <a
            href="https://www.facebook.com/share/BdoAoDwk8oSjj3Ry/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={20} className="text-SecondTextColor" />
          </a>
          <a
            href="https://www.instagram.com/tec.rek?igsh=b2M4aTVpbXRuejBs&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagramSquare size={20} className="text-SecondTextColor" />
          </a>
          <a
            href="https://www.linkedin.com/company/tecrek/posts/?feedView=all&viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={20} className="text-SecondTextColor" />
          </a>
        </div>
        <label
          htmlFor="theme-toggle"
          className="inline-flex gap-2 relative items-center cursor-pointer"
        >
          <input
            type="checkbox"
            id="theme-toggle"
            className="sr-only theme-switch-checkbox" // Hidden checkbox
            checked={theme === "dark"}
            onChange={() => setTheme(theme === "light" ? "dark" : "light")}
          />
          <div
            className="theme-switch-slider bg-gray-400 
          dark:bg-SecondTextColor w-11 h-6 rounded-full 
          shadow-inner relative before:absolute before:top-1 before:left-1 
          before:bg-white before:border before:border-gray-300 before:rounded-full 
          before:h-4 before:w-4 before:transition-all before:duration-300"
          ></div>
          <span
            className={`ml-3 text-sm font-medium ${
              isDarkMode ? "text-gray-300" : "text-gray-900"
            }`}
          >
            {isDarkMode ? t("Enable Light Mode") : t("Enable Dark Mode")}
          </span>
        </label>
        <LanguageButton footMode={true} />
      </div>

      <div className="flex flex-col gap-2">
        <h1 className="text-text18 font-bold">{t("Quick Links")}</h1>
        <div className="flex flex-col gap-2">
          <Link
            to="/"
            className="hover:text-SecondTextColor duration-300 w-fit"
          >
            {t("Home")}
          </Link>
          <Link
            to="/aboutUs"
            className="hover:text-SecondTextColor duration-300 w-fit"
          >
            {t("About Us")}
          </Link>
          <Link
            to="/ourWork"
            className="hover:text-SecondTextColor duration-300 w-fit"
          >
            {t("Our Work")}
          </Link>
          <Link
            to="/courseDetails/1"
            className="hover:text-SecondTextColor duration-300 w-fit"
          >
            {t("Courses")}
          </Link>
          {showLink && (
            <Link
              onClick={() =>
                window.scrollTo({
                  top: document.getElementById("contact_us").offsetTop,
                  behavior: "smooth",
                })
              }
              className="hover:text-SecondTextColor duration-300 w-fit"
            >
              {t("Contact Us")}
            </Link>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h1 className="text-text18 font-bold">{t("Get in touch")}</h1>
        <div className="flex flex-col gap-2">
          <div
            onClick={() => setChooseContactUsWayOpen(true)}
            className="flex items-center gap-2 cursor-pointer md:hidden"
          >
            <FaPhone className="text-SecondTextColor" /> +4915733965543
          </div>
          <Link
            to="https://wa.me/4915733965543"
            target="_blank"
            className="flex items-center gap-2 cursor-pointer max-md:hidden"
          >
            <IoLogoWhatsapp className="text-SecondTextColor" /> +4915733965543
          </Link>
          <Link
            to="mailto:support@tecrek.com"
            className="flex items-center gap-2"
          >
            <FaMessage className="text-SecondTextColor" /> support@tecrek.com
          </Link>
        </div>
      </div>
      <ChooseContactUsWay />
      <SelectLanguagePopup />
    </div>
  );
}

export default Footer;
