import React, { useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import americaFlag from "../../assets/Flags/americaFlag.png";
import germanyFlag from "../../assets/Flags/germanyFlag.png";
import saudiArabiaFlag from "../../assets/Flags/saudiArabiaFlag.png";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { PopupContext } from "../../Context/PopupContext";
import Popup from "./Popup";

function SelectLanguagePopup({ dashMode }) {
  const lng = localStorage.getItem("language") || "English";
  const { theme } = useTheme();
  const { i18n, t } = useTranslation();
  const { setDir } = useContext(PopupContext);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng);
    setLanguageOpen(false);
    setDir(lng === "Arabic" ? "rtl" : "ltr");
  };
  const { languageOpen, setLanguageOpen } = useContext(PopupContext);
  return (
    <Popup
      toggleSwitch={languageOpen}
      children={
        <div
          className={`${
            theme && !dashMode === "dark"
              ? "bg-DarkMainBg text-DarkMainText"
              : ""
          } ${
            dashMode && "text-MainTextColor max-md:text-[12px]"
          } z-50 md:w-[460px] max-md:w-full`}
        >
          <div className="flex items-center justify-center h-[50px] relative py-[2px] w-full border-b border-b-MainTextColor/40">
            <AiOutlineClose
              className={`${
                lng === "Arabic" ? "left-[10px]" : "right-[10px]"
              } absolute cursor-pointer`}
              onClick={() => setLanguageOpen(false)}
            />
            <h1
              className={`${
                theme === "dark" ? "text-DarkMainText" : "text-MainText"
              } ${!dashMode && "text-[19px]"} font-[700]`}
            >
              {t("Choose Language")}
            </h1>
          </div>
          <div>
            <ul
              className={`${
                !dashMode ? "max-w-[320px] my-[10px]" : "p-2"
              } mx-auto`}
            >
              <li
                onClick={() => {
                  changeLanguage("English");
                }}
                className={`${
                  dashMode ? "py-[10px]" : "py-[20px]"
                } flex items-center gap-6 border-b border-b-[#ededed] cursor-pointer`}
              >
                <img
                  className="w-[28px] h-[20px] rounded-sm"
                  src={americaFlag}
                  alt=""
                />
                <h1>{t("English")}</h1>
              </li>
              <li
                onClick={() => {
                  changeLanguage("Deutsch");
                }}
                className={`${
                  dashMode ? "py-[10px]" : "py-[20px]"
                } flex items-center gap-6 border-b border-b-[#ededed] cursor-pointer`}
              >
                <img
                  className="w-[28px] h-[20px] rounded-sm"
                  src={germanyFlag}
                  alt=""
                />
                <h1>{t("Deutsch")}</h1>
              </li>
              <li
                onClick={() => {
                  changeLanguage("Arabic");
                }}
                className={`${
                  dashMode ? "py-[10px]" : "py-[20px]"
                } flex items-center gap-6 cursor-pointer`}
              >
                <img
                  className="w-[28px] h-[20px] rounded-sm"
                  src={saudiArabiaFlag}
                  alt=""
                />
                <h1>{t("Arabic")}</h1>
              </li>
            </ul>
          </div>
        </div>
      }
      noDarkMode={true}
      zIndex="z-40 animate-flashing"
      className={`${
        languageOpen ? "opacity-100 z-50" : "opacity-0"
      } top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText languageBoxShadow duration-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0 animate-flashing`}
      onClick={() => setLanguageOpen(false)}
    />
  );
}

export default SelectLanguagePopup;
