import React from "react";
import { FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTheme } from "../../Context/ThemeContext";

function TeamMember({ image, name, position, link }) {
  const { theme } = useTheme();
  return (
    <div className="flex flex-col max-h-[500px] w-full rounded-[2px] max-lg:w-[70%]">
      <div className="rounded-lg overflow-hidden">
        <img className="w-full h-full object-cover" src={image} alt="" />
      </div>
      <div className="flex flex-col">
        <h1
          className={`bg-gradient-to-r from-SecondTextColor ${
            theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
          } text-transparent bg-clip-text leading-[26px] text-[20px] max-xl:text-text18 max-md:text-text16 my-1 font-bold `}
        >
          {name.split("/")[0]}
        </h1>
        <h2 className="md:text-text18 max-md:text-text16 font-semibold mb-3">
          {position}
        </h2>
        <Link to={link}>
          <FaLinkedin size={20} />
        </Link>
      </div>
    </div>
  );
}

export default TeamMember;
