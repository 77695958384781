import { React, useContext, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Popup from "./Popup";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { PopupContext } from "../../Context/PopupContext";
import { logout } from "../../APIs/api";
import { SuccessToast } from "./ToastifyNotification/Toast";
import { IoMdCheckmark } from "react-icons/io";
import { MdClose } from "react-icons/md";

function MakeDecision() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { makeDecisionOpen, setMakeDecisionOpen, type, action } =
    useContext(PopupContext);
  const handleConfirm = async () => {
    setLoading(true);
    if (action === "logout") {
      try {
        const response = await logout();
        console.log(response);
        if (response.success === true) {
          Cookies.remove("tecrekAdminToken");
          SuccessToast(response.message);
          window.location.pathname = "/admin";
        }
      } catch (error) {
        console.error(`${action} ${type} Error`, error);
      } finally {
        setLoading(false);
        setMakeDecisionOpen(false);
      }
    }
  };
  return (
    <Popup
      toggleSwitch={makeDecisionOpen}
      children={
        <div
          className={`text-MainTextColor max-md:min-w-full overflow-hidden flex flex-col gap-2`}
        >
          <h1 className="font-[500]">
            {t("Are you sure you want to")}{" "}
            {action === "logout" ? t("logout") : null}{" "}
            {type ? `${t("this")} ${type}` : null}
          </h1>
          <div className="flex items-center justify-start mt-3 gap-3">
            <button
              onClick={() => setMakeDecisionOpen(false)}
              className="py-[7.5px] px-[12px] text-[13px] cursor-pointer rounded-[10px] border border-[#9ea5ad] hover:border-MainTextColor hover:bg-MainTextColor hover:text-white duration-300 flex justify-center items-center min-w-[50px] min-h-[30px]"
            >
              <MdClose />
            </button>

            <button
              onClick={handleConfirm}
              className={`${
                loading
                  ? "cursor-default pointer-events-none"
                  : "cursor-pointer"
              } py-[7.5px] px-[12px] text-[13px] rounded-[10px] border border-[#9ea5ad] hover:border-MainTextColor hover:bg-MainTextColor hover:text-white duration-300 flex justify-center items-center min-w-[50px] min-h-[30px]`}
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                <IoMdCheckmark />
              )}
            </button>
          </div>
        </div>
      }
      noDarkMode={true}
      zIndex={`z-40`}
      className={`text-MainText bg-white top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] fixed z-50 overflow-hidden rounded-[10px] p-4`}
      onClick={() => setMakeDecisionOpen(false)}
    />
  );
}

export default MakeDecision;
