import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function TeamMemberSkeleton() {
  return (
    <div className="flex flex-col w-[60%] min-h-[400px]">
      <Skeleton className="rounded-xl basis-[60%] h-[200px]" />
      <div className="flex flex-col basis-[40%] w-full">
        <Skeleton className="rounded-xl h-[20px] my-3" width={120} />
        <Skeleton className="rounded-xl h-[20px] mb-3" width={100} />
        <Skeleton className="rounded-xl h-[20px]" width={40} />
      </div>
    </div>
  );
}

export default TeamMemberSkeleton;
