import React, { useState, useRef, useEffect } from "react";
import NavBar from "../Components/NavBar";
import Container from "../Components/Container";
import Footer from "../Components/Footer";
import ScrollTopButton from "../Components/ScrollTopButton";
import MainButton from "../Components/MainButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import { getSurveyQuetions, postSurveyAnswers } from "../../APIs/api";
import { toast } from "react-toastify";
import { LoadingToast } from "../Components/ToastifyNotification/Toast";
import { useNavigate, useParams } from "react-router";
import SurveySkeleton from "../Components/SkeletonLoading/SurveySkeleton";

function SurveyByStudentNumber() {
  const navigate = useNavigate();
  const { studentNumber } = useParams();
  const lng = localStorage.getItem("language");
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [emptyQuestions, setEmptyQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [student, setStudent] = useState({
    id: "",
    firstName: "",
    lastName: "",
  });
  const questionRefs = useRef([]);

  const fetchSurveyData = async () => {
    try {
      setLoading(true);
      const response = await getSurveyQuetions(studentNumber);
      setStudent({
        id: response.data.student.id,
        firstName: response.data.student.first_name,
        lastName: response.data.student.last_name,
      });
      const questionsWithOthers = response.data.questions.map((question) => ({
        ...question,
        options: [
          ...question.options,
          {
            id: "",
            text_en: "Others",
            text_ar: "إحابة آخرى",
            text_gr: "Eine andere Antwort",
          },
        ],
      }));
      setQuestions(questionsWithOthers);
    } catch (err) {
      console.error("fetch survey data err: ", err);
    } finally {
      setLoading(false);
    }
  };

  const handleAnswerChange = (index, answer) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [index]: answer,
    }));

    // Remove from emptyQuestions if it was empty before
    setEmptyQuestions((prev) => prev.filter((i) => i !== index));
  };

  const handleOtherInputChange = (index, value) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [`other_${index}`]: value,
    }));

    // Remove from emptyQuestions if it was empty before
    setEmptyQuestions((prev) => prev.filter((i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newEmptyQuestions = questions
      .slice(0, 25)
      .map((question, index) => {
        const selectedOption = selectedAnswers[index];
        const isOtherSelected =
          selectedOption ===
          (question.options.find(
            (opt) => (opt[`text_${lng}`] || opt.text_en) === "Others"
          )?.[`text_${lng}`] || "Others");

        const isOtherInputEmpty = !selectedAnswers[`other_${index}`];

        if (!selectedOption || (isOtherSelected && isOtherInputEmpty)) {
          return index;
        } else {
          return null;
        }
      })
      .filter((index) => index !== null);

    if (newEmptyQuestions.length > 0) {
      setEmptyQuestions(newEmptyQuestions);
      questionRefs.current[newEmptyQuestions[0]].scrollIntoView({
        behavior: "smooth",
      });
    } else {
      try {
        const formData = new FormData();

        questions.slice(0, 25).forEach((question, index) => {
          formData.append(`ids[${index}][question_id]`, question.id);
          const selectedOption = question.options.find(
            (opt) =>
              opt[`text_${lng}`] === selectedAnswers[index] ||
              opt.text_en === selectedAnswers[index]
          );

          if (selectedOption) {
            if (selectedOption.id === "") {
              // Others option
              formData.append(`ids[${index}][option_id]`, "");
              formData.append(
                `ids[${index}][response]`,
                selectedAnswers[`other_${index}`] || ""
              );
            } else {
              formData.append(`ids[${index}][option_id]`, selectedOption.id);
              formData.append(`ids[${index}][response]`, "");
            }
          }
        });
        const toastId = LoadingToast("Wait a few seconds");
        const response = await postSurveyAnswers(student.id, formData);
        console.log(response);
        toast.dismiss(toastId);
        if (response.success === "true") {
          navigate(`/ComputerSurvey/${studentNumber}`);
        }
      } catch (err) {
        console.error("post survey answers err : ", err);
      }
    }
  };

  useEffect(() => {
    fetchSurveyData();
  }, [lng]);

  return (
    <div>
      <Container>
        <NavBar />
        <div className="flex flex-col gap-5 max-md:p-[10px] md:mb-[50px]">
          <div>
            {t(
              "We invite all parents to participate in completing 25 questions designed to deepen our understanding of your children’s personalities. Your participation is crucial in helping us tailor an educational experience that fits the unique traits and needs of each child. We guarantee complete confidentiality of all information provided and promise that it will be used solely to enhance the support we offer our students."
            )}
          </div>
          <div>
            {t(
              "Thank you for your engagement and commitment to improving education at our school."
            )}
          </div>
          <div>
            <span
              className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${
                theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
              } text-transparent bg-clip-text`}
            >
              {t("Child Name")} :{" "}
            </span>{" "}
            {`${student.firstName} ${student.lastName}`}
          </div>
          <form className="flex flex-col gap-5" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-5">
              {loading
                ? Array.from({ length: 25 }).map((_, index) => (
                    <div key={index}>
                      <SurveySkeleton />
                    </div>
                  ))
                : questions.map((question, index) => (
                    <div
                      key={question.id}
                      ref={(el) => (questionRefs.current[index] = el)}
                      className={`rounded-[10px] ${index > 24 && "hidden"} ${
                        emptyQuestions.includes(index)
                          ? "border border-red-500"
                          : ""
                      } flex flex-col gap-2`}
                    >
                      <h3
                        className={`text-text18 w-fit bg-gradient-to-r from-SecondTextColor ${
                          theme === "dark"
                            ? "to-MainTextColorDM"
                            : "to-MainTextColor"
                        } text-transparent bg-clip-text max-md:text-text16`}
                      >
                        {question.id}
                        {" - "}
                        {lng === "Arabic"
                          ? question.text_ar
                          : lng === "Deutsch"
                          ? question.text_gr
                          : question.text_en}
                      </h3>
                      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-2">
                        {question.options.map((option) => (
                          <label
                            key={option.id}
                            className="cursor-pointer flex items-center gap-1"
                          >
                            <input
                              type="radio"
                              name={`question-${index}`}
                              value={option[`text_${lng}`] || option.text_en}
                              onChange={() =>
                                handleAnswerChange(
                                  index,
                                  option[`text_${lng}`] || option.text_en
                                )
                              }
                            />
                            <span>
                              {lng === "Arabic"
                                ? option.text_ar
                                : lng === "Deutsch"
                                ? option.text_gr
                                : option.text_en}
                            </span>
                          </label>
                        ))}
                        {selectedAnswers[index] ===
                          (question.options.find(
                            (opt) =>
                              (opt[`text_${lng}`] || opt.text_en) === "Others"
                          )?.[`text_${lng}`] || "Others") && (
                          <input
                            className={`mt-2 px-3 py-2 border rounded-lg outline-none cursor-pointer ${
                              theme === "dark"
                                ? "hover:border-white focus:border-white bg-black/25 focus:bg-black/25"
                                : "hover:border-black focus:border-black bg-gray-300/25 focus:bg-gray-300/25 placeholder:text-[#777]"
                            } ${
                              emptyQuestions.includes(index)
                                ? "border border-red-500"
                                : ""
                            }`}
                            type="text"
                            placeholder={t("Please specify")}
                            value={selectedAnswers[`other_${index}`] || ""}
                            onChange={(e) =>
                              handleOtherInputChange(index, e.target.value)
                            }
                          />
                        )}
                      </div>
                      {emptyQuestions.includes(index) && (
                        <p className={`text-red-500 mt-1`}>
                          {t("This question is required")}
                        </p>
                      )}
                    </div>
                  ))}
            </div>
            <MainButton
              content={t("Send")}
              type="submit"
              className="w-[100px]"
            />
          </form>
        </div>
        <Footer />
        <ScrollTopButton />
      </Container>
    </div>
  );
}

export default SurveyByStudentNumber;
