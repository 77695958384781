import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { useTheme } from "../../Context/ThemeContext";
import { useTranslation } from "react-i18next";

function ProjectInformation({ project }) {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const lng = localStorage.getItem("language") || "English";
  const description =
    lng === "English"
      ? project?.description
      : lng === "Deutsch"
      ? project?.description_gr
      : project?.description_ar;
  return (
    <div className="flex flex-col mt-[30px] mb-[20px] md:my-[50px] xl:my-[110px] max-md:mt-5 gap-[30px]">
      <div className="flex flex-col gap-3">
        <h1
          className={`font-bold text-[48px] max-xl:text-[40px] max-md:text-[26px] max-md:mb-1 leading-[40px] mb-[10px] bg-gradient-to-br from-SecondTextColor ${
            theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
          } text-transparent bg-clip-text`}
        >
          {project?.name}
        </h1>
        <h1 className="font-semibold text-[32px] leading-[40px] mb-[10px] bg-clip-text   max-md:text-[24px]">
          {lng === "English"
            ? project?.main_description
            : lng === "Deutsch"
            ? project?.main_description_gr
            : project?.main_description_ar}
        </h1>
        <div
          style={{ direction: "ltr" }}
          className="w-full mt-5 max-xl:mx-0 max-md:mx-auto mySwiper"
        >
          <Swiper
            modules={[Pagination, Navigation, Autoplay]}
            spaceBetween={10}
            pagination={{ clickable: true }}
            navigation
            speed={500}
            slidesPerView="auto"
            autoplay
          >
            {project?.product_image__web.length > 0 && (
              <SwiperSlide>
                <div className="w-full h-[300px] md:h-[500px]">
                  <img
                    className="w-full h-full object-contain"
                    src={project?.product_image__web[0].url_image}
                    alt={`img web`}
                  />
                </div>
              </SwiperSlide>
            )}

            {project?.product_image__app.length > 0 && (
              <SwiperSlide>
                <div className="w-full h-[300px] md:h-[500px]">
                  <img
                    className="w-full h-full object-contain"
                    src={project?.product_image__app[0].url_image}
                    alt={`img mobile`}
                  />
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
        <div className="flex flex-col gap-[30px]">
          {description &&
            Object.entries(description).map(([key, value]) => (
              <div key={key} className="flex flex-col gap-2 max-md:px-[10px]">
                <div
                  className={`min-w-[100px] font-semibold text-text18 bg-gradient-to-r from-SecondTextColor ${
                    theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
                  } text-transparent bg-clip-text`}
                >
                  {key}
                </div>
                <div>{value}</div>
              </div>
            ))}
        </div>
      </div>
      {project?.product_image__web.length > 0 && (
        <div className="flex flex-col gap-4">
          <div
            className={`font-semibold text-[24px] bg-gradient-to-br from-SecondTextColor ${
              theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
            } text-transparent bg-clip-text`}
          >
            {t("Web images")}
          </div>
          <div
            style={{ direction: "ltr" }}
            className="w-full mt-5 max-xl:mx-0 max-md:mx-auto mySwiper"
          >
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation
              autoplay
              speed={500}
              breakpoints={{
                1280: {
                  slidesPerView: 1.5,
                },
                1024: {
                  slidesPerView: 1.2,
                },
                768: {
                  slidesPerView: 1,
                },
              }}
            >
              {project?.product_image__web.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="w-full ">
                      <img
                        className="w-full h-full object-contain"
                        src={item.url_image}
                        alt={`img(${index})`}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      )}
      {project?.product_image__app.length > 0 && (
        <div className="flex flex-col gap-4">
          <div
            className={`font-semibold text-[24px] bg-gradient-to-br from-SecondTextColor ${
              theme === "dark" ? "to-MainTextColorDM" : "to-MainTextColor"
            } text-transparent bg-clip-text`}
          >
            {t("Mobile app images")}
          </div>
          <div
            style={{ direction: "ltr" }}
            className="w-full mt-5 max-xl:mx-0 max-md:mx-auto mySwiper"
          >
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              spaceBetween={10}
              pagination={{ clickable: true }}
              navigation
              autoplay
              speed={500}
              breakpoints={{
                1280: {
                  slidesPerView: 3,
                },
                1024: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 1,
                },
              }}
            >
              {project?.product_image__app.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="w-full h-[300px] md:h-[500px]">
                      <img
                        className="w-full h-full object-contain"
                        src={item.url_image}
                        alt={`img(${index})`}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectInformation;
